import { FC } from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import styles from './index-dynamic-form-fields.module.scss';

export interface IRadioProps {
  name: string;
  onChange: (name: string, value: string) => void;
  value?: string;
  options: [
    {
      key: string;
      label: string;
    }
  ];
  disabled?: boolean;
}

export const Radio: FC<IRadioProps> = ({
  name,
  onChange,
  value,
  options,
  disabled,
}) => {
  const handleValueChange = (e: RadioChangeEvent) => {
    onChange(name, e.target.value);
  };

  return (
    <AntdRadio.Group
      disabled={disabled}
      name={name}
      value={value}
      onChange={handleValueChange}
    >
      {options.map(({ key, label }) => (
        <AntdRadio className={styles.Radio} key={key} value={key}>
          {label}
        </AntdRadio>
      ))}
    </AntdRadio.Group>
  );
};
