import React, { useState, useEffect } from 'react';
import {
  ArrowLeftOutlined,
  CameraOutlined,
  UserOutlined,
  LoadingOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import { Button, IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import Webcam from 'react-webcam';
import { Layout, Content, Footer } from '@datapeace/vms-visitor-components';
import styles from './shared-face-capture.module.scss';
import {
  IBlobWithDataURL,
  getImageFromVideo,
  useCountdown,
} from '@datapeace/1up-frontend-web-utils';

interface Props {
  onCapture: (faceData: IBlobWithDataURL) => Promise<void>;
  goBack: () => void;
}

export function SharedFaceCapture({ onCapture, goBack }: Props) {
  const [isRearCameraPosition, setIsRearCameraPosition] = useState(false);
  const [videoElement, setVideoElement] = useState<Webcam | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [capturedImage, setCapturedImage] = useState<IBlobWithDataURL | null>(
    null
  );

  const handleCapture = async () => {
    try {
      setIsLoading(true);
      if (!videoElement?.video) {
        throw new Error('Failed to capture (No video!)');
      }

      const faceData = await getImageFromVideo(videoElement.video);
      setCapturedImage(faceData);

      if (!faceData?.size) {
        throw new Error('Failed to capture!');
      }

      await onCapture(faceData);
    } catch (err) {
      toast.error(err as IErrorResponse);
      setCapturedImage(null);
      setIsLoading(false);
    }
  };

  const [transitionDelay, resetTransitionDelay] = useCountdown(1, 200);
  useEffect(() => {
    if (!isLoading) resetTransitionDelay();
  }, [isLoading, resetTransitionDelay]);

  const handleCameraPosition = () => {
    setIsRearCameraPosition(!isRearCameraPosition);
  };

  return (
    <Layout>
      {!isLoading && (
        <div
          className={clsx(
            styles.HintText,
            !!transitionDelay && styles.HintTextInitial
          )}
        >
          <div>
            <span>
              <UserOutlined className={styles.HintTextIcon} />
              Take photo
            </span>
          </div>
        </div>
      )}

      {isLoading && (
        <div className={styles.HintText}>
          <div>
            <Spin indicator={<LoadingOutlined />} size="large" />
          </div>
        </div>
      )}

      <Button
        icon={<SwapOutlined />}
        size="large"
        className={styles.CameraPosition}
        onClick={handleCameraPosition}
        shape="circle"
      />

      <Content
        className={clsx(
          styles.Content,
          !isRearCameraPosition && styles.MirrorImage
        )}
      >
        {capturedImage && <img src={capturedImage.dataURL} alt="" />}

        {!capturedImage && (
          <Webcam
            audio={false}
            autoPlay
            ref={setVideoElement}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: isRearCameraPosition ? 'environment' : 'user',
            }}
          />
        )}
      </Content>

      <Footer className={styles.Footer}>
        <Button
          onClick={goBack}
          className={styles.BackButton}
          size="large"
          icon={<ArrowLeftOutlined />}
          shape="circle"
        />

        <Button
          onClick={handleCapture}
          loading={isLoading}
          disabled={isLoading}
          className={styles.CaptureButton}
          icon={<CameraOutlined />}
          shape="circle"
        />
      </Footer>
    </Layout>
  );
}
