/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  MobileOutlined,
  RedoOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { Button, Alert, StatusCard } from '@datapeace/1up-frontend-web-ui';
import * as Sentry from '@sentry/react';
import { Collapse, Descriptions, Space } from 'antd';
import moment from 'moment';
import clsx from 'clsx';
import customFormStyles from '../custom-form-screen/custom-form-screen.module.scss';
import styles from './confirm-screen.module.scss';
import { useProcessDataContext } from '@datapeace/vms-visitor-models';
import {
  convertToCamelCase,
  getMaskedMobileNumber,
} from '@datapeace/1up-frontend-shared-api';
import {
  Layout,
  Header,
  Content,
  NavButtons,
} from '@datapeace/vms-visitor-components';
import { SENTRY_DSN } from '@datapeace/vms-visitor-utils';
import { useRedirectOnDone } from '@datapeace/vms-visitor-hooks';
type ICustomMessage = {
  title: string;
  displayText?: string | React.ReactNode;
  extraJSX?: React.ReactNode;
} | null;

export const Confirm: FC = () => {
  const {
    isCheckinProcess,
    registerData,
    faceData,
    visitorBadgeUrl,
    handleProcessConfirm,
    goBack,
    visitorBadgeAutoDownloadEnabled,
    personData,
  } = useProcessDataContext();
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [customMessage, setCustomMessage] = useState<ICustomMessage>(null);

  const {
    firstName = '',
    lastName = '',
    mobileNumber = '',
  } = registerData || {};
  const { pictureUrl } = personData || {};
  const fullName = `${firstName} ${lastName}`;

  const [resendDelay] = useRedirectOnDone(
    status,
    `${visitorBadgeUrl}&download=${visitorBadgeAutoDownloadEnabled}`
  );

  function getErrorJSX(sentryJSX: React.ReactNode, err: any) {
    return (
      // @ts-ignore
      <Collapse className={styles.TechnicalDetailsCollapse}>
        {/* @ts-ignore */}
        <Collapse.Panel header="Technical Details" key="1">
          <Descriptions column={1}>
            <Descriptions.Item label="Timestamp">{`${moment().format(
              'lll'
            )}`}</Descriptions.Item>
            <Descriptions.Item label="Request Id">
              {err?.response?.headers?.['x-1upvision-request-id']}
            </Descriptions.Item>
            <Descriptions.Item label="Message">
              {err?.message}
            </Descriptions.Item>
          </Descriptions>
          {import.meta.env.NODE_ENV !== 'test' &&
          import.meta.env.VITE_APP_ENVIRONMENT !== 'local' &&
          SENTRY_DSN
            ? sentryJSX
            : null}
        </Collapse.Panel>
      </Collapse>
    );
  }

  const handleSubmit = useCallback(async () => {
    setStatus('loading');
    setCustomMessage({
      title: `Pre-Checking ${isCheckinProcess ? 'in' : 'out'}`,
    });

    const successCustomMessage: ICustomMessage = {
      title: `Pre-check${isCheckinProcess ? 'in' : 'out'} successful!`,
    };
    try {
      await handleProcessConfirm();
      setCustomMessage({ ...successCustomMessage });
      setStatus('success');
    } catch (err) {
      setStatus('error');
      const displayText = {
        ...convertToCamelCase((err as any)?.fields),
      }.displayText as string | undefined;

      const errorName = (err as any)?.response?.data?.error?.name as
        | string
        | undefined;

      const { nonFieldErrors } = convertToCamelCase(
        (err as any)?.fields || {}
      ) as {
        nonFieldErrors: string[] | undefined;
      };
      setCustomMessage({
        title:
          (err as any)?.fields?.title ||
          `Check${isCheckinProcess ? 'in' : 'out'} Failed`,
        displayText:
          displayText ||
          (!!nonFieldErrors &&
            (nonFieldErrors?.length === 1 ? (
              nonFieldErrors?.[0]
            ) : (
              <ul>{nonFieldErrors?.map((errMessage) => errMessage)}</ul>
            ))) ||
          'Please check and modify the information before resubmitting.',
        extraJSX:
          !nonFieldErrors &&
          (err as any)?.response?.status === 400 &&
          errorName !== 'CheckinNotAllowed' &&
          errorName !== 'CheckoutNotAllowed' &&
          errorName !== 'PrecheckinNotAllowed'
            ? getErrorJSX(
                // eslint-disable-next-line react/jsx-indent
                <Button
                  onClick={() => {
                    Sentry.withScope((scope) => {
                      scope.setExtra(
                        'x-1upvision-request-id',
                        (err as any)?.response?.headers?.[
                          'x-1upvision-request-id'
                        ]
                      );
                      const sentryError = new Error(
                        `Error-in-${
                          isCheckinProcess ? 'checkin' : 'checkout'
                        }: ${(err as any).message}`
                      );
                      sentryError.stack = (err as any).stack;
                      Sentry.captureException(sentryError);
                    });
                    setCustomMessage((prev) => ({
                      ...(prev || {
                        title:
                          (err as any)?.fields?.title ||
                          `Check${isCheckinProcess ? 'in' : 'out'} Failed`,
                        displayText:
                          displayText ||
                          'Please check and modify the information before resubmitting.',
                      }),
                      extraJSX: getErrorJSX(
                        <Alert
                          message="Our team has been notified of this error"
                          type="success"
                          showIcon
                        />,
                        err
                      ),
                    }));
                  }}
                  danger
                >
                  Report this error
                </Button>,
                err
              )
            : null,
      });
    }
  }, [setStatus, isCheckinProcess, handleProcessConfirm]);

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  const errorActions = [
    <Button icon={<RedoOutlined />} danger size="large" onClick={handleSubmit}>
      Retry
    </Button>,
  ];

  return (
    <Layout>
      <Header />
      <Content>
        <div className={customFormStyles.Form}>
          <StatusCard
            title={`${fullName}`}
            highlight
            meta={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <h2 style={{ fontSize: '20px', fontWeight: 'normal' }}>
                  <div>
                    <small>
                      <MobileOutlined />
                      &nbsp;
                      {mobileNumber && getMaskedMobileNumber(mobileNumber)}
                    </small>
                  </div>
                </h2>
              </div>
            }
            status={status}
            actions={status === 'error' ? errorActions : undefined}
            message={customMessage?.title || ''}
            avatar={faceData ? faceData?.dataUrl : pictureUrl || ''}
          >
            {customMessage && (
              <div>
                {customMessage?.displayText && (
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    {customMessage.displayText}
                  </p>
                )}
                {customMessage?.extraJSX && customMessage.extraJSX}
              </div>
            )}
          </StatusCard>

          {status === 'success' ? (
            <Alert
              className={clsx(styles.RedirectAlert, styles.success)}
              type="success"
              showIcon={false}
              message={
                <Space>
                  <Space>
                    <ClockCircleOutlined
                      style={{
                        color: '#1d8102',
                      }}
                    />
                    {`This screen will redirect in ${resendDelay} seconds`}
                  </Space>
                  <Button
                    onClick={
                      () =>
                        window.location.replace(
                          `${visitorBadgeUrl}&download=${visitorBadgeAutoDownloadEnabled}`
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    type="dashed"
                  >
                    Redirect Now
                  </Button>
                </Space>
              }
              banner
            />
          ) : null}
          {status === 'error' && (
            <NavButtons showNext={false} goBack={goBack} />
          )}
        </div>
      </Content>
    </Layout>
  );
};
