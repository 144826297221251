import { useState } from 'react';
import { Button, IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  Layout,
  Header,
  Content,
  NavButtons,
  Form,
  DynamicForm,
} from '@datapeace/vms-visitor-components';
import { useProcessDataContext } from '@datapeace/vms-visitor-models';
import styles from '../custom-form-screen/custom-form-screen.module.scss';
import { useCountdown } from '@datapeace/1up-frontend-web-utils';

export const MobileVerification = () => {
  const {
    mobileNumber,
    otpLength,
    handleVerifyMobile,
    handleResendOtpToMobile,
    goBack,
  } = useProcessDataContext();
  const [formData, setFormData] = useState({ otp: '' });
  const [isLoading, setIsLoading] = useState(false);

  const [resendDelay, resetResendDelay] = useCountdown(10);

  const handleSubmit = async (codeValue?: string | null) => {
    try {
      const otp = codeValue || formData.otp;
      setIsLoading(true);

      await handleVerifyMobile(otp, mobileNumber);
    } catch (err) {
      setFormData({ otp: '' });
      toast.error(err as IErrorResponse);
      setIsLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    try {
      resetResendDelay();
      await handleResendOtpToMobile(mobileNumber);
      toast.success({ message: 'OTP sent' });
    } catch (err) {
      toast.error(err as IErrorResponse);
    }
    setIsLoading(false);
  };

  const fields = [
    {
      type: 'verificationCode',
      name: 'otp',
      label: `Please verify the OTP sent to ${mobileNumber}`,
      codeLength: otpLength,
      onSubmit: handleSubmit,
      disabled: isLoading,
    },
  ];

  return (
    <Layout>
      <Header />

      <Content>
        <div className={styles.Form}>
          <Form onSubmit={() => handleSubmit()} noValidate autoComplete="off">
            <DynamicForm
              items={fields}
              values={formData}
              onChange={setFormData}
            />

            <div>
              <Button
                disabled={!!resendDelay}
                onClick={handleResendOtp}
                size="small"
                type="ghost"
              >
                Resend OTP?
                {!!resendDelay && (
                  <span style={{ marginLeft: 4 }}>{`(${resendDelay})`}</span>
                )}
              </Button>
            </div>
            <NavButtons loading={isLoading} goBack={goBack} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
