export const API_BASE_URL = import.meta.env.VITE_APP_API_BASE_URL;
export const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;
export const ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT;
export const ASSET_BASE_PATH = import.meta.env.VITE_APP_ASSET_BASE_PATH;
export const DISABLED_TNC_EMAIL: boolean =
  import.meta.env.VITE_APP_DISABLE_TNC_EMAIL_ME_COPY === 'true' || false;

if (API_BASE_URL === undefined) {
  throw Error(
    'Please set environment variable `VITE_APP_API_BASE_URL` correctly.'
  );
}

if (ASSET_BASE_PATH === undefined || ASSET_BASE_PATH === null) {
  throw Error(
    'Please set ASSET_BASE_PATH environment variables correctly! Empty string can also be set.'
  );
}

export const defaultLogo =
  ASSET_BASE_PATH === ''
    ? `${ASSET_BASE_PATH}/images/vms_logo.png`
    : `${ASSET_BASE_PATH}/vms_logo.png`;

export const ROUTES = {
  HOME: '/',
  CAPTURE_FACE: '/capture-face',
  REGISTER: '/register',
  INVITE_DETAILS: '/invite-details',
  CHECKIN_BY_FACE: '/checkin-by-face',
  CHECKIN_BY_MOBILE: '/checkin-by-mobile',
  CHECK_IN_FORM: '/check-in-form',
  SCREENING_FORM: '/screening-form',
  VACCINATION_FORM: '/vaccination-form',
  TERMS: '/terms',
  MOBILE_VERIFY: '/mobile-verify',
  CHECK_IN_SUCCESS: '/check-in-success',
  VISITBADGE: '/visitor-badge',
  VISIT_INVITATION_VISITOR_BADGE: '/visitor-invitation-badge/',
} as const;

export const COMPARISON_OPERATORS = {
  EQUAL: '==',
  NOT_EQUAL: '!=',
};

const timestamp = new Date().getTime();
export const imageWithTimestamp = (imgUrl: string) =>
  imgUrl?.includes('?')
    ? `${imgUrl}&v=${timestamp}`
    : `${imgUrl}?v=${timestamp}`;
