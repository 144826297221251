import { FC, useState } from 'react';
import {
  toast,
  StatusCard,
  IErrorResponse,
} from '@datapeace/1up-frontend-web-ui';
import { MobileOutlined } from '@ant-design/icons';
import {
  Layout,
  Header,
  Content,
  NavButtons,
} from '@datapeace/vms-visitor-components';
import formStyles from '../custom-form-screen/custom-form-screen.module.scss';
import { useProcessDataContext } from '@datapeace/vms-visitor-models';
import { getMaskedMobileNumber } from '@datapeace/1up-frontend-shared-api';

export const InviteDetails: FC = () => {
  const { personData, handleConfirmInvite, spaceName, orgName, goBack } =
    useProcessDataContext();
  const [isLoading, setIsLoading] = useState(false);
  if (!personData) {
    throw new Error('Person data not found in InviteDetails screen!');
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await handleConfirmInvite();
    } catch (err) {
      toast.error(err as IErrorResponse);
      setIsLoading(false);
    }
  };

  const { firstName, lastName, mobileNumber, pictureUrl } = personData;
  const fullName = `${firstName} ${lastName}`;

  return (
    <Layout>
      <Header />
      <Content>
        <div className={formStyles.Form}>
          <StatusCard
            status={isLoading ? 'loading' : 'idle'}
            style={{ marginBottom: '1em' }}
            avatar={pictureUrl}
            title={
              <h2 style={{ fontSize: '20px', fontWeight: 'normal' }}>
                {fullName}

                <div>
                  <small>
                    <MobileOutlined />
                    &nbsp;
                    {mobileNumber && getMaskedMobileNumber(mobileNumber)}
                  </small>
                </div>
              </h2>
            }
            message={
              <p>
                {'You have been invited by '}
                <em>{orgName}</em>
                {' to visit space '}
                <em>{spaceName}</em>
              </p>
            }
          />

          {!isLoading && (
            <NavButtons
              loading={isLoading}
              goBack={goBack}
              onNext={handleSubmit}
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};
