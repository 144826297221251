import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './layout.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const Footer: FC<Props> = ({ className, children }) => (
  <div className={clsx(styles.Footer, className)}>{children}</div>
);
