import {
  CheckinByFace,
  CheckinByMobile,
  FaceCaptureScreen,
  InviteDetails,
  MobileVerification,
  ScreeningForm,
  VisitInvitationVisitorBadge,
  Terms,
  Register,
  VaccinationForm,
  VmsQrScanner,
  CustomForm,
  Confirm,
  VisitBadge,
} from '@datapeace/vms-visitor-screens';
import { useRouter } from '@datapeace/vms-visitor-models';
import { ROUTES } from '@datapeace/vms-visitor-utils';

const routes = {
  [ROUTES.HOME]: VmsQrScanner,
  [ROUTES.REGISTER]: Register,
  [ROUTES.INVITE_DETAILS]: InviteDetails,
  [ROUTES.MOBILE_VERIFY]: MobileVerification,
  [ROUTES.CHECKIN_BY_FACE]: CheckinByFace,
  [ROUTES.CHECKIN_BY_MOBILE]: CheckinByMobile,
  [ROUTES.SCREENING_FORM]: ScreeningForm,
  [ROUTES.VACCINATION_FORM]: VaccinationForm,
  [ROUTES.CHECK_IN_FORM]: CustomForm,
  [ROUTES.TERMS]: Terms,
  [ROUTES.CHECK_IN_SUCCESS]: Confirm,
  [ROUTES.CAPTURE_FACE]: FaceCaptureScreen,
  [ROUTES.VISITBADGE]: VisitBadge,
  [ROUTES.VISIT_INVITATION_VISITOR_BADGE]: VisitInvitationVisitorBadge,
};

export const Routes = () => {
  const { currentRoute } = useRouter();
  const RouteToRender = routes[currentRoute];

  return <RouteToRender />;
};
