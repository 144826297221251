import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './layout.module.scss';

interface Props {
  className?: string;
  showBackground?: boolean;
  children?: React.ReactNode;
}

export const Layout: FC<Props> = ({ className, children }) => {
  return <div className={clsx(styles.Layout, className)}>{children}</div>;
};
