import { FC, useState } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  Layout,
  Header,
  Content,
  NavButtons,
  DynamicForm,
  Form,
} from '@datapeace/vms-visitor-components';
import { IRegisterData, useProcessDataContext } from '../../models';
import formStyles from '../custom-form-screen/custom-form-screen.module.scss';
import { startCase } from '@datapeace/1up-frontend-web-utils';

export const Register: FC = () => {
  const {
    personData,
    registerData,
    handleSubmitRegisterForm,
    isCheckinProcess,
    countryCallingCode,
    goBack,
  } = useProcessDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<IRegisterData>({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    ...registerData,
  });

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const {
        firstName: firstNameRaw,
        lastName: lastNameRaw,
        mobileNumber,
      } = formData;
      const firstName = startCase(firstNameRaw);
      const lastName = startCase(lastNameRaw);
      setFormData({ ...formData, firstName, lastName });

      await handleSubmitRegisterForm({ firstName, lastName, mobileNumber });
    } catch (err) {
      toast.error(err as IErrorResponse);
      setIsLoading(false);
    }
  };
  // if already registered and has data, keep fields readOnly (non-editable)
  const firstNameReadOnly = !isCheckinProcess || !!personData?.firstName;
  const lastNameReadOnly = !isCheckinProcess || !!personData?.lastName;
  // const mobileReadOnly = !isCheckinProcess || !!personData?.mobileNumber;
  const mobileReadOnly = true; // will already have mobile if reached this step

  const detailsFormSchema = [
    {
      type: 'text',
      name: 'firstName',
      label: 'First Name',
      required: true,
      disabled: firstNameReadOnly,
      multiline: false,
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Last Name',
      required: true,
      disabled: lastNameReadOnly,
      multiline: false,
    },
    {
      type: 'tel',
      defaultPrefix: countryCallingCode,
      name: 'mobileNumber',
      label: 'Mobile Number',
      disabled: mobileReadOnly,
      required: true,
    },
  ];

  return (
    <Layout>
      <Header />
      <Content>
        <div className={formStyles.Form}>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DynamicForm
              values={formData}
              items={detailsFormSchema}
              onChange={setFormData}
            />
            <NavButtons loading={isLoading} goBack={goBack} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
