import { createContext, useContext, FC, useState, useCallback } from 'react';
import { ROUTES } from '@datapeace/vms-visitor-utils';
import { updateUrlPath } from '@datapeace/1up-frontend-shared-api';

export type RoutesValues = ValueOf<typeof ROUTES>;

export const RouterContext = createContext<{
  currentRoute: RoutesValues;
  setCurrentRoute: (newRoute: RoutesValues) => null;
  handleRedirectToHome: () => void;
} | null>(null);

export const useRouter = () => {
  const routerData = useContext(RouterContext);

  if (!routerData) {
    throw new Error("RouterContext should be consumed inside it's Provider");
  }

  return routerData;
};

export const RouterProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [currentRoute, setRoute] = useState<RoutesValues>(ROUTES.HOME);

  const setCurrentRoute = useCallback((newRoute: RoutesValues) => {
    setRoute(newRoute);
    return null;
  }, []);

  const handleRedirectToHome = useCallback(() => {
    updateUrlPath('/');
    setCurrentRoute(ROUTES.HOME);
  }, [setCurrentRoute]);

  return (
    <RouterContext.Provider
      value={{ currentRoute, setCurrentRoute, handleRedirectToHome }}
    >
      {children}
    </RouterContext.Provider>
  );
};
