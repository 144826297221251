/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  VerifyVaccinationCertificate,
  verifyVaccinationCertificateQR,
} from '@datapeace/1up-frontend-shared-api';
import {
  checkVaccinationProcess,
  useCountdown,
  useIsWindowFocused,
  useVaccinationCertificateQRReader,
  useWindowSize,
} from '@datapeace/1up-frontend-web-utils';
import { Alert, Modal, message } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';
import { IErrorResponse } from '../toast';
import { Button } from '../button';
import { Content, Layout } from '../layout';
import clsx from 'clsx';
import { Loader } from '../loader';
import { QrcodeOutlined, SwapOutlined } from '../icons';
import { Camera } from '../camera';
import styles from './vaccination-verification.module.scss';

export interface VaccinationQRScannerProps {
  onSubmit: (args: VerifyVaccinationCertificate | null) => void;
  isPartiallyVaccinatedAllowed: boolean;
  axiosInstance: AxiosInstance;
  btnSize?: SizeType | 'xLarge';
}

export function VaccinationQRScanner({
  onSubmit,
  isPartiallyVaccinatedAllowed,
  axiosInstance,
  btnSize = 'middle',
}: VaccinationQRScannerProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>();
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );
  const [isVerifying, setIsVerifying] = useState(false);
  const [videoFacingMode, setVideoFacingMode] = useState<
    'environment' | 'user'
  >('environment');

  const { width, height } = useWindowSize();
  const canvasWidth = Math.round(width / 2);
  const canvasHeight = Math.round(height / 2);
  const minWidth = 1280;
  const minHeight = 720;
  const isWindowFocused = useIsWindowFocused(true);
  const [qrReaderCanvasElement, setQrReaderCanvasElement] =
    useState<HTMLCanvasElement | null>(null);

  async function handleQrFound(screenshotBase64: string) {
    if (isVerifying) return;
    if (!screenshotBase64) return;
    setIsVerifying(true);
    try {
      const res = await verifyVaccinationCertificateQR(
        screenshotBase64,
        axiosInstance
      );
      checkVaccinationProcess(res, isPartiallyVaccinatedAllowed);
      message.success('Vaccination Certificate Verified!');
      setIsModalOpen(false);
      onSubmit(res);
    } catch (e) {
      console.error(e);
      message.error(`${(e as IErrorResponse).message}. Please try again`);
      onSubmit(null);
    } finally {
      setIsVerifying(false);
    }
  }

  useVaccinationCertificateQRReader(
    videoElement,
    qrReaderCanvasElement,
    handleQrFound,
    isVerifying
  );

  const [transitionDelay, resetTransitionDelay] = useCountdown(1, 200);
  useEffect(() => {
    resetTransitionDelay();
  }, [resetTransitionDelay]);

  return (
    <>
      <Button
        size={btnSize}
        style={{ marginTop: '1rem' }}
        onClick={() => setIsModalOpen(true)}
      >
        Scan QR Code
      </Button>
      {/* @ts-ignore */}
      <Modal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        destroyOnClose
        width="90vw"
        style={{ top: 20, left: 0, height: '90vh' }}
        bodyStyle={{
          height: '90vh',
          width: '100%',
          padding: 0,
          display: 'flex',
          margin: 0,
        }}
        maskStyle={{ background: 'rgba(0, 0, 0, 0.9)' }}
      >
        <Layout className={styles.QRLayout}>
          <div
            className={clsx(
              styles.HintText,
              !!transitionDelay && styles.HintTextInitial
            )}
          >
            <div>
              <span>
                {isVerifying ? (
                  <Loader text="Verifying..." />
                ) : (
                  <QrcodeOutlined className={styles.HintTextIcon} />
                )}
                Show Vaccination Certificate QR
              </span>
            </div>
          </div>
          <Content className={styles.QRContent}>
            {isWindowFocused ? (
              <>
                <Camera
                  videoConstraints={{ facingMode: videoFacingMode }}
                  loading={isVerifying}
                  captureAreaBoxSize={0}
                  videoElementRef={(el) => setVideoElement(el)}
                  info=""
                  hideCaptureButton
                />
                <canvas
                  width={canvasWidth > minWidth ? canvasWidth : minWidth}
                  height={canvasHeight > minHeight ? canvasHeight : minHeight}
                  ref={setQrReaderCanvasElement}
                />
                <div className={styles.QrFrame} />
                <Button
                  className={styles.ToggleViewModeBtn}
                  shape="circle"
                  icon={<SwapOutlined />}
                  onClick={() =>
                    setVideoFacingMode((prev) =>
                      prev === 'environment' ? 'user' : 'environment'
                    )
                  }
                />
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '1rem',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Alert
                  message="Tap on screen to see camera view!"
                  description="This window is out of focus. Tap here to see the camera view."
                  type="warning"
                  showIcon
                />
              </div>
            )}
          </Content>
        </Layout>
      </Modal>
    </>
  );
}
