import { FC, useEffect, useRef } from 'react';
import OtpInput from 'react-otp-input';
import styles from './index-dynamic-form-fields.module.scss';

export interface IVerificationCodeProps {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  codeType?: 'text' | 'number';
  disabled?: boolean;
  codeLength?: number;
  autoFocus?: boolean;
  onSubmit: (val: string) => void;
}

const usePreventNumberInput = (
  element: HTMLDivElement | null,
  active = true
) => {
  useEffect(() => {
    if (!element || !active) return () => null;

    const handleInput = (e: Event) => {
      if (!(e.target instanceof HTMLInputElement)) return;

      if (Number.isNaN(+e.target.value)) {
        e.preventDefault();
        e.stopPropagation();
        e.target.value = '';
      }
    };
    element.addEventListener('input', handleInput);

    return () => {
      element.removeEventListener('input', handleInput);
    };
  }, [element, active]);
};

export const VerificationCode: FC<IVerificationCodeProps> = ({
  name,
  value = '',
  onChange,
  codeType = 'number',
  disabled,
  codeLength,
  autoFocus,
  onSubmit,
}) => {
  // temp fix for 'zero not being accepted as initial value' (https://github.com/devfolioco/react-otp-input/issues/31)
  const otpInputContainerRef = useRef<HTMLDivElement>(null);
  usePreventNumberInput(otpInputContainerRef.current, codeType === 'number');

  const handleValueChange = (val: string | number) => {
    const changedValue = String(val || '');
    onChange(name, changedValue);

    if (changedValue.length === codeLength) {
      onSubmit(changedValue);
    }
  };

  return (
    <div ref={otpInputContainerRef}>
      <OtpInput
        containerStyle={styles.OtpBoxContainer}
        inputStyle={`ant-input ant-input-lg ${styles.OtpBox}`}
        value={value}
        onChange={handleValueChange}
        // isInputNum={codeType === 'number'}
        isDisabled={disabled}
        numInputs={codeLength || 4}
        shouldAutoFocus={autoFocus}
      />
    </div>
  );
};
