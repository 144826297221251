import { CookieChecker, OnlineStatus } from '@datapeace/1up-frontend-web-ui';
import { Routes } from './routes';
import { RouterProvider, ProcessDataProvider } from './models';
import { AppInitializerContainer } from '@datapeace/vms-visitor-containers';

function App() {
  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <CookieChecker logoUrl="/images/vms_logo.png">
        <AppInitializerContainer>
          <OnlineStatus>
            <RouterProvider>
              <ProcessDataProvider>
                <Routes />
              </ProcessDataProvider>
            </RouterProvider>
          </OnlineStatus>
        </AppInitializerContainer>
      </CookieChecker>
    </div>
  );
}

export default App;
