import { useState } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import { useProcessDataContext } from '@datapeace/vms-visitor-models';
import styles from '../custom-form-screen/custom-form-screen.module.scss';
import {
  Layout,
  Header,
  Content,
  Form,
  DynamicForm,
  NavButtons,
} from '@datapeace/vms-visitor-components';

export const CheckinByMobile = () => {
  const { countryCallingCode, handleSendOtpToMobile, goBack } =
    useProcessDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ mobileNumber: '' });

  const handleSubmit = async () => {
    const { mobileNumber } = formData;

    try {
      setIsLoading(true);
      await handleSendOtpToMobile(mobileNumber);
    } catch (err) {
      setIsLoading(false);
      toast.error(err as IErrorResponse);
    }
  };

  const formSchema = [
    {
      type: 'tel',
      defaultPrefix: countryCallingCode,
      name: 'mobileNumber',
      label: 'Enter your mobile number',
      required: true,
      disablePrefix: true,
    },
  ];

  return (
    <Layout>
      <Header />
      <Content>
        <div className={styles.Form}>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DynamicForm
              values={formData}
              items={formSchema}
              onChange={setFormData}
            />
            <NavButtons loading={isLoading} goBack={goBack} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
