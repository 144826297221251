import React, { useState, useEffect, useRef, FC } from 'react';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  TagOutlined,
  CommentOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Button, Result, Tooltip, Typography } from 'antd';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { Helmet } from 'react-helmet';
import { ResultStatusType } from 'antd/lib/result';
import styles from './visit-invitation-visitor-badge-screen.module.scss';
import {
  Layout,
  Content,
  Header,
  Loader,
} from '@datapeace/vms-visitor-components';
import {
  IInvitationBadge,
  downloadFileFromUrl,
} from '@datapeace/1up-frontend-shared-api';
import {
  Api,
  defaultLogo,
  imageWithTimestamp,
} from '@datapeace/vms-visitor-utils';

function getDataFromUrl(url: string) {
  try {
    const { searchParams } = new URL(url);

    const invitationToken = searchParams.get('token');

    return invitationToken || null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

function InfoListItem({
  icon: Icon,
  content = null,
  children = null,
  tooltip,
  html2canvasIgnore = false,
}: {
  icon: typeof CalendarOutlined;
  content?: React.ReactNode;
  children?: React.ReactNode;
  tooltip: string;
  html2canvasIgnore?: boolean;
}) {
  return (
    <Tooltip title={tooltip} placement="leftTop">
      <div
        className={styles.InfoListItem}
        id={html2canvasIgnore ? 'data-html2canvas-ignore' : 'InfoListItem'}
      >
        <Icon className={styles.InfoIcon} />
        <div className={styles.InfoContent}>{children || content}</div>
      </div>
    </Tooltip>
  );
}

export const VisitInvitationVisitorBadge: FC = () => {
  const [invitationToken] = useState(() =>
    getDataFromUrl(window.location.toString())
  );

  const [badgeDetails, setBadgeDetails] = useState<null | IInvitationBadge>(
    null
  );
  const [isComplete, setIsComplete] = useState(false);
  const [validationStatus, setValidationStatus] = useState<{
    status: ResultStatusType;
    message: string;
  }>({
    status: '500',
    message: '',
  });
  const refContainer = useRef<HTMLDivElement | null>(null);

  const startDate = moment(badgeDetails?.visitInvitation.start);
  const endDate = moment(badgeDetails?.visitInvitation.end);

  const isSameDate = startDate.isSame(endDate, 'day');
  const startDateString = startDate.format('llll');
  const endDateString = isSameDate
    ? endDate.format('LT')
    : endDate.format('llll');

  useEffect(() => {
    async function fetchInvitationBadge(inviteCode: string) {
      try {
        const data = await Api.getVisitInvitationBadge(inviteCode);
        setBadgeDetails(data);
      } catch (err) {
        console.error(err);
        const responseStatus = (err as any)?.response?.status;
        if (responseStatus === 400) {
          setValidationStatus({
            status: '404',
            message: 'The link you followed is not valid or has expired.',
          });
        } else {
          setValidationStatus({
            status: '500',
            message: 'Oops, something went wrong. Please try again later.',
          });
        }
        setIsComplete(true);
      }
    }
    fetchInvitationBadge(invitationToken as string);
  }, [invitationToken]);

  function download() {
    const container = refContainer.current;
    if (!container) {
      return;
    }
    html2canvas(container, {
      allowTaint: false,
      useCORS: true,
      ignoreElements(element) {
        if (element.id === 'data-html2canvas-ignore') {
          return true;
        }
        return false;
      },
      onclone: (document) => {
        const hiddenDiv = document.getElementById('hidden-div');
        if (hiddenDiv) {
          hiddenDiv.style.display = 'inline-flex';
        }
      },
    }).then((canvas) => {
      const url = canvas.toDataURL('image/png', 0.95);
      downloadFileFromUrl(
        url,
        `visitor-invitation-badge-${badgeDetails?.visitInvitation?.id || 0}.png`
      );
    });
  }

  if (isComplete) {
    return (
      <>
        <Helmet>
          <title>Invitation Badge</title>
        </Helmet>
        <Header imgSrc={badgeDetails?.organization?.logoUrl || defaultLogo} />
        <Result
          status={validationStatus.status}
          title={validationStatus.message}
          subTitle="You can close this tab now."
        />
      </>
    );
  }

  if (!badgeDetails) {
    return (
      <>
        <Helmet>
          <title>Invitation Badge</title>
        </Helmet>
        <Loader text="Loading..." inline={false} />
      </>
    );
  }
  return (
    <Layout>
      <Helmet>
        <title>Invitation Badge</title>
      </Helmet>
      <Header imgSrc={badgeDetails?.organization?.logoUrl || defaultLogo} />
      <Content>
        <div className={styles.Content}>
          <div ref={refContainer}>
            <div className={styles.Header} style={{ textAlign: 'center' }}>
              <img
                src={
                  imageWithTimestamp(
                    badgeDetails?.organization?.logoUrl as string
                  ) || imageWithTimestamp(defaultLogo)
                }
                alt="logo"
                className={styles.BrandImage}
                style={{ display: 'none' }}
                crossOrigin="anonymous"
                id="hidden-div"
              />
              <Typography.Title
                level={3}
                style={{
                  textAlign: 'center',
                }}
                type="secondary"
              >
                Invitation Badge
              </Typography.Title>
              <div className={styles.BadgeQrInfo}>
                You have been invited to visit, please show this QR for seamless
                checkin/checkout.
              </div>
            </div>

            <div className={styles.InfoList}>
              <InfoListItem
                icon={TagOutlined}
                content={<strong>{badgeDetails?.visitInvitation.name}</strong>}
                tooltip="Invitation Name"
              />

              <InfoListItem
                icon={ClockCircleOutlined}
                tooltip="Invitation Time"
                content={
                  <>
                    {startDateString}
                    <span> - </span>
                    {!isSameDate && <br />}
                    {endDateString}
                  </>
                }
              />

              <InfoListItem
                icon={EnvironmentOutlined}
                content={badgeDetails?.visitInvitation.locationText}
                tooltip="Invitation Location"
              />

              <InfoListItem
                icon={CalendarOutlined}
                tooltip="Add to calendar"
                html2canvasIgnore
                content={
                  <>
                    <strong>Add to Calendar</strong>
                    <div className={styles.CalendarLinks}>
                      <a
                        href={badgeDetails?.calendarUrls.google}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google
                      </a>
                      &#8226;
                      <a
                        href={badgeDetails?.calendarUrls.outlook}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Outlook
                      </a>
                      &#8226;
                      <a
                        href={badgeDetails?.calendarUrls.apple}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Apple
                      </a>
                    </div>
                  </>
                }
              />
              {!!badgeDetails?.visitInvitation?.visitorComment && (
                <InfoListItem
                  icon={CommentOutlined}
                  content={
                    badgeDetails?.visitInvitation?.visitorComment || '---'
                  }
                  tooltip="Visitor Comment"
                />
              )}
              <div className={styles.QrContainer}>
                {!!badgeDetails?.qrCodeBase64 && (
                  <img
                    alt="qr_code"
                    src={badgeDetails?.qrCodeBase64}
                    crossOrigin="anonymous"
                  />
                )}
              </div>
            </div>
            <div className={styles.Footer}>
              Powered by&nbsp;
              <a
                rel="noopener noreferrer"
                href={import.meta.env.VITE_APP_BRAND_DOMAIN}
                target="_blank"
              >
                {import.meta.env.VITE_APP_BRAND_NAME?.toUpperCase()}
              </a>
            </div>
          </div>

          <div className={styles.DownloadBtn}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              size="large"
              onClick={() => download()}
            >
              Download
            </Button>
          </div>
        </div>
      </Content>
    </Layout>
  );
};
