import { FC } from 'react';
import { Switch } from 'antd';
import styles from './index-dynamic-form-fields.module.scss';

export interface IToggleProps {
  name: string;
  onChange: (name: string, checked: boolean) => void;
  value?: boolean;
  disabled?: boolean;
}

export const Toggle: FC<IToggleProps> = ({
  name,
  onChange,
  value,
  disabled,
}) => {
  const handleValueChange = (checked: boolean) => {
    onChange(name, checked);
  };

  return (
    <Switch
      className={styles.Toggle}
      disabled={disabled}
      checked={value}
      onChange={handleValueChange}
    />
  );
};
