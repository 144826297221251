import clsx from 'clsx';
import {
  Layout,
  Header,
  Content,
  NavButtons,
  Form,
} from '@datapeace/vms-visitor-components';
import DefaultFormStyles from '../../components/form/form.module.scss';
import styles from './vaccination-form-screen.module.scss';
import { useProcessDataContext } from '@datapeace/vms-visitor-models';
import { visitorAppClient } from '@datapeace/1up-frontend-shared-api';
import {
  UploadVaccinationCertificate,
  VaccinationQRScanner,
} from '@datapeace/1up-frontend-web-ui';

export function VaccinationForm() {
  const {
    vaccinationVerificationSkipAllowed,
    handleSubmitVaccinationForm,
    partiallyVaccinatedVisitorCheckinAllowed,
    goBack,
  } = useProcessDataContext();
  return (
    <Layout>
      <Header />
      <Content className={styles.VaccinationForm}>
        <h2>Vaccination Verification</h2>
        <Form
          className={clsx(DefaultFormStyles.Form, styles.VaccinationForm__Form)}
        >
          <p>Please upload your vaccination certificate</p>
          <UploadVaccinationCertificate
            isPartiallyVaccinatedAllowed={
              partiallyVaccinatedVisitorCheckinAllowed
            }
            onSubmit={handleSubmitVaccinationForm}
            axiosInstance={visitorAppClient}
            btnSize="middle"
          />
          <VaccinationQRScanner
            isPartiallyVaccinatedAllowed={
              partiallyVaccinatedVisitorCheckinAllowed
            }
            onSubmit={handleSubmitVaccinationForm}
            axiosInstance={visitorAppClient}
            btnSize="middle"
          />
        </Form>
        <NavButtons
          goBack={goBack}
          showNext={vaccinationVerificationSkipAllowed}
          nextLinkText="Skip"
          onNext={() => handleSubmitVaccinationForm(null, true)}
        />
      </Content>
    </Layout>
  );
}
