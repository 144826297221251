import React, {
  createContext,
  useContext,
  useReducer,
  useRef,
  FC,
} from 'react';
import {
  getCountriesData,
  validatePhoneWithPrefix,
  IVisit,
  IForm,
  IScreeningDataFormSchema,
  IPerson,
  IVisitRequest,
  IFormFieldData,
  IClientMetaData,
  VaccinationVerificationData,
  getDeviceFingerprint,
  VerifyVaccinationCertificate,
} from '@datapeace/1up-frontend-shared-api';
import * as Sentry from '@sentry/react';
import { ROUTES, Api } from '@datapeace/vms-visitor-utils';
import { validateFields, getVisibleFields } from '../utils/form';
import {
  normalizeValueObject,
  getValueObject,
} from '@datapeace/vms-visitor-components';
import { getFieldsDataFromConfig } from '@datapeace/vms-visitor-screens';
import { RoutesValues, useRouter } from '@datapeace/vms-visitor-models';
import {
  IBlobWithDataURL,
  getDeviceGeolocation,
  parseVaccinationData,
  useRefValue,
} from '@datapeace/1up-frontend-web-utils';

const SCREENING_DECLARATION_FIELD_KEY = 'SCREENING_DECLARATION_FIELD_KEY';

export enum ProcessType {
  Checkin = 'Checkin',
  Checkout = 'Checkout',
}

export interface IFaceData {
  dataUrl: string;
  imageUrl: string;
}

export interface IRegisterData {
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

export interface IDynamicForm {
  [key: string]: any;
}

export type PortalType = 'invite-precheckin' | 'vms-visitor';

interface IState {
  // spaceId: number | null;
  accessToken: string | null;
  portalType: PortalType;

  // space config
  portalTitle: string;
  portalBackgroundImageUrl: string;
  portalLogoUrl: string;
  hasMobileFeaturePlan: boolean;
  spaceName: string;
  spaceId?: string;
  // org config
  otpLength: number;
  countryCallingCode: string;
  orgName: string;
  orgSlug?: string;
  deviceId?: string;
  username?: string;
  // input data
  mobileNumber: string;
  /** person data filled by user */
  registerData: IRegisterData | null;

  // visit details
  /** person data received from API */
  personData: IPerson | null;
  activeVisit: IVisit | null;
  lastVisit: IVisit | null;
  visitRequestId: number | null;
  isCheckinProcess: boolean;
  isFaceRequired: boolean;
  isScreeningRequired: boolean;
  isTncRequired: boolean;
  isCustomFormRequired: boolean;
  // customFormFields: IFormField[];
  customFormSchemaRevisionId: number | null;
  checkinFormSchema: IForm;
  checkoutFormSchema: IForm;
  screeningFormSchema: IScreeningDataFormSchema;
  tncContent: string;
  customFormData: IDynamicForm | null;
  screeningFormData: IDynamicForm | null;
  vaccinationCertificateData: VaccinationVerificationData | null;
  lastVisitValues: IDynamicForm | null;
  activeVisitValues: IDynamicForm | null;
  prefillCheckinValues: IDynamicForm | null;
  prefillCheckoutValues: IDynamicForm | null;
  checkinFormAutofillEnabled: boolean;
  checkoutFormAutofillEnabled: boolean;

  faceData: IFaceData | null;
  signatureImage: string;
  tncEmail: string;

  qrCodeBase64: string;

  visitorBadgeUrl: string;
  visitorBadgeAutoDownloadEnabled: boolean;
  isVaccinationVerificationRequired: boolean;
  vaccinationVerificationSkipAllowed: boolean;
  partiallyVaccinatedVisitorCheckinAllowed: boolean;
}

const initialState: IState = {
  // initial
  // spaceId: null,
  accessToken: null,
  portalType: 'vms-visitor',

  // space config
  portalTitle: '',
  portalBackgroundImageUrl: '',
  portalLogoUrl: '',
  hasMobileFeaturePlan: false,
  spaceName: '',

  // org config
  otpLength: 4,
  // countryCode: '',
  countryCallingCode: '',
  orgName: '',

  // input data
  mobileNumber: '',

  // process data
  personData: null,
  activeVisit: null,
  lastVisit: null,
  isCheckinProcess: false,
  isFaceRequired: false,
  isScreeningRequired: false,
  isTncRequired: false,
  visitRequestId: null,
  isCustomFormRequired: false,
  // customFormFields: [],
  customFormSchemaRevisionId: null,
  checkinFormSchema: { fields: [] },
  checkoutFormSchema: { fields: [] },
  screeningFormSchema: {
    title: '',
    fields: [],
    description: '',
    identifier: '',
  },
  lastVisitValues: null,
  activeVisitValues: null,
  prefillCheckinValues: null,
  prefillCheckoutValues: null,
  checkinFormAutofillEnabled: false,
  checkoutFormAutofillEnabled: false,

  tncContent: '',
  faceData: null,
  registerData: null, // person data filled by user
  customFormData: null,
  screeningFormData: null,
  vaccinationCertificateData: null,
  signatureImage: '',
  tncEmail: '',

  qrCodeBase64: '',

  visitorBadgeUrl: '',
  visitorBadgeAutoDownloadEnabled: false,
  isVaccinationVerificationRequired: false,
  vaccinationVerificationSkipAllowed: true,
  partiallyVaccinatedVisitorCheckinAllowed: true,
};

type IAction =
  | { type: 'ResetProcessData' }
  | { type: 'UpdateProcessData'; payload: Partial<IState> };

interface IProcessActions {
  resetProcessData: () => void;
  updateProcessData: (state: Partial<IState>) => void;

  goBack: () => void;

  handleUploadImage: (imgaeData: IBlobWithDataURL) => Promise<string>;

  handleScanVmsQr: (data: {
    type: PortalType;
    spaceSlug: string;
    token?: string;
    visitRequestId?: number;
  }) => Promise<{ expiresIn: number }>;
  handleCheckinByFaceSubmit: (faceData: IBlobWithDataURL) => Promise<void>;
  handleSendOtpToMobile: (mobileNumber: string) => Promise<void>;
  handleResendOtpToMobile: (mobile: string) => Promise<void>;
  handleVerifyMobile: (otp: string, mobile: string) => Promise<void>;
  handleSubmitRegisterForm: (formData: {
    firstName: string;
    lastName: string;
    mobileNumber: string;
  }) => Promise<void>;
  handleConfirmInvite: () => Promise<void>;
  handleCaptureFace: (faceData: IBlobWithDataURL) => Promise<void>;
  handleSubmitScreeningForm: (screeningFormData: IDynamicForm) => void;
  handleSubmitVaccinationForm: (
    vaccinationCertificateData: any,
    skip?: boolean
  ) => void;
  handleSubmitTerms: (signatureImageDataUrl: string, tncEmail?: string) => void;
  handleSubmitCustomForm: (customFormData: IDynamicForm) => void;
  handleProcessConfirm: () => Promise<void>;
}

const handleProcessVisitRequestInfo = ({
  visitRequestInfo,
  isCheckinProcess,
  registerData,
  lastVisit,
}: {
  visitRequestInfo: Pick<
    IVisitRequest,
    'id' | 'spaceConfig' | 'visitRequiredFields'
  >;
  isCheckinProcess: boolean;
  registerData: { firstName: string; lastName: string; mobileNumber: string };
  lastVisit: IVisit | null;
}) => {
  const { id, spaceConfig, visitRequiredFields } = visitRequestInfo;
  const {
    checkinFormSchema,
    checkinFormSchemaRevisionId,
    checkoutFormSchema,
    checkoutFormSchemaRevisionId,
    tncContent,
    checkinFormAutofillEnabled,
    checkoutFormAutofillEnabled,
  } = spaceConfig;
  const {
    isCheckinFaceRequired,
    isCheckoutFaceRequired,
    isCheckinFormRequired,
    isCheckoutFormRequired,
    isVaccinationVerificationRequired,
    vaccinationVerificationSkipAllowed,
    partiallyVaccinatedVisitorCheckinAllowed,
  } = visitRequiredFields;

  const customFormSchema = isCheckinProcess
    ? checkinFormSchema
    : checkoutFormSchema;
  const customFormSchemaRevisionId = isCheckinProcess
    ? checkinFormSchemaRevisionId
    : checkoutFormSchemaRevisionId;

  const isCustomFormRequired =
    !!customFormSchema?.fields?.length &&
    (isCheckinProcess ? isCheckinFormRequired : isCheckoutFormRequired);

  const isFaceRequired = isCheckinProcess
    ? isCheckinFaceRequired
    : isCheckoutFaceRequired;
  const isScreeningRequired =
    isCheckinProcess && visitRequiredFields.isScreeningRequired;
  const isTncRequired = isCheckinProcess && visitRequiredFields.isTncRequired;

  const screeningFormSchemaFields = [
    ...(spaceConfig.screeningFormSchema?.fields || []).map((item) => ({
      ...item,
      required: !spaceConfig.checkinWithoutScreening,
    })),
    {
      name: SCREENING_DECLARATION_FIELD_KEY,
      label: 'Declaration',
      type: 'checkbox',
      required: !spaceConfig.checkinWithoutScreening,
      options: [
        {
          key: 'declaration',
          label:
            'I, hereby declare that the information furnished above is true, complete and correct to the best of my knowledge and belief. I understand that in the event of my information being found false or incorrect at any stage, I shall be responsible for the consequences.',
        },
      ],
    },
  ];
  const screeningFormSchema = {
    ...spaceConfig.screeningFormSchema,
    fields: screeningFormSchemaFields,
  };

  let lastVisitValues = null;

  if (
    isCheckinProcess &&
    checkinFormAutofillEnabled &&
    lastVisit?.checkinCustomData
  ) {
    lastVisitValues = normalizeValueObject(
      lastVisit.checkinCustomData,
      checkinFormSchema?.fields
    );
  }

  if (
    !isCheckinProcess &&
    checkoutFormAutofillEnabled &&
    lastVisit?.checkoutCustomData
  ) {
    lastVisitValues = normalizeValueObject(
      lastVisit.checkoutCustomData,
      checkoutFormSchema?.fields
    );
  }

  return {
    isCustomFormRequired,
    registerData,
    visitRequestId: id,
    checkinFormSchema,
    checkoutFormSchema,
    customFormSchemaRevisionId,
    screeningFormSchema,
    isFaceRequired,
    isScreeningRequired,
    isTncRequired,
    tncContent,
    checkinFormAutofillEnabled,
    checkoutFormAutofillEnabled,
    lastVisitValues,
    isVaccinationVerificationRequired,
    vaccinationVerificationSkipAllowed,
    partiallyVaccinatedVisitorCheckinAllowed,
  };
};

interface IProcessDataContext extends IState, IProcessActions {}

export const ProcessDataContext = createContext<IProcessDataContext | null>(
  null
);

export const useProcessDataContext = () => {
  const processData = useContext(ProcessDataContext);

  if (!processData) {
    throw new Error(
      "ProcessDataContext should be consumed inside it's Provider"
    );
  }

  return processData;
};

const reducer: React.Reducer<IState, IAction> = (state, action) => {
  switch (action.type) {
    case 'ResetProcessData':
      return initialState;

    case 'UpdateProcessData':
      return { ...state, ...action.payload };

    default:
      throw new Error('Invalid action!');
  }
};

const getNextRoute = (
  currentRoute: RoutesValues,
  state: Pick<
    IState,
    | 'isFaceRequired'
    | 'isScreeningRequired'
    | 'isVaccinationVerificationRequired'
    | 'isCustomFormRequired'
    | 'isTncRequired'
    | 'hasMobileFeaturePlan'
    | 'personData'
  > & { portalType: PortalType }
): RoutesValues => {
  const routesOrder = [
    { key: ROUTES.HOME, isShown: true },
    {
      key: ROUTES.CHECKIN_BY_MOBILE,
      isShown: state.portalType !== 'invite-precheckin',
    },
    // { key: ROUTES.CHECKIN_BY_FACE, isShown: false },
    {
      key: ROUTES.MOBILE_VERIFY,
      isShown: state.portalType !== 'invite-precheckin',
    },
    { key: ROUTES.REGISTER, isShown: state.portalType !== 'invite-precheckin' },
    {
      key: ROUTES.INVITE_DETAILS,
      isShown: state.portalType === 'invite-precheckin',
    },
    {
      key: ROUTES.CAPTURE_FACE,
      isShown:
        !state.hasMobileFeaturePlan &&
        (!state.personData || !state.personData.hasIndexedFaces),
    },
    { key: ROUTES.SCREENING_FORM, isShown: state.isScreeningRequired },
    {
      key: ROUTES.VACCINATION_FORM,
      isShown: state.isVaccinationVerificationRequired,
    },
    { key: ROUTES.CHECK_IN_FORM, isShown: state.isCustomFormRequired },
    { key: ROUTES.TERMS, isShown: state.isTncRequired },
    { key: ROUTES.CHECK_IN_SUCCESS, isShown: true },
  ];

  const currentRouteIndex = routesOrder.findIndex(
    (route) => route.key === currentRoute
  );

  const nextRoute = routesOrder[currentRouteIndex + 1];

  if (!nextRoute) {
    console.warn('No next route found! Returning home route');
    return ROUTES.HOME;
  }

  if (!nextRoute.isShown) {
    // this will allow finding the next route with `isShown` set to true
    return getNextRoute(nextRoute.key, state);
  }

  return nextRoute.key;
};

function getPreviousRoute(
  currentRoute: RoutesValues,
  state: Pick<
    IState,
    | 'isFaceRequired'
    | 'isScreeningRequired'
    | 'isVaccinationVerificationRequired'
    | 'isCustomFormRequired'
    | 'isTncRequired'
    | 'hasMobileFeaturePlan'
    | 'personData'
  > & { portalType: PortalType }
): RoutesValues {
  const routesOrder = [
    { key: ROUTES.HOME, isShown: true },
    {
      key: ROUTES.CHECKIN_BY_MOBILE,
      isShown: state.portalType !== 'invite-precheckin',
    },
    // { key: ROUTES.CHECKIN_BY_FACE, isShown: false },
    {
      key: ROUTES.MOBILE_VERIFY,
      isShown: state.portalType !== 'invite-precheckin',
    },
    { key: ROUTES.REGISTER, isShown: state.portalType !== 'invite-precheckin' },
    {
      key: ROUTES.INVITE_DETAILS,
      isShown: state.portalType === 'invite-precheckin',
    },
    {
      key: ROUTES.CAPTURE_FACE,
      isShown:
        !state.hasMobileFeaturePlan &&
        (!state.personData || !state.personData.hasIndexedFaces),
    },
    { key: ROUTES.SCREENING_FORM, isShown: state.isScreeningRequired },
    {
      key: ROUTES.VACCINATION_FORM,
      isShown: state.isVaccinationVerificationRequired,
    },
    { key: ROUTES.CHECK_IN_FORM, isShown: state.isCustomFormRequired },
    { key: ROUTES.TERMS, isShown: state.isTncRequired },
    { key: ROUTES.CHECK_IN_SUCCESS, isShown: true },
  ];
  const currentRouteIndex = routesOrder.findIndex(
    (route) => route.key === currentRoute
  );

  const previousRoute = routesOrder[currentRouteIndex - 1];

  if (!previousRoute) {
    console.warn('No previous route found! Returning home route');
    return ROUTES.HOME;
  }

  if (previousRoute.key === ROUTES.MOBILE_VERIFY) {
    return getPreviousRoute(ROUTES.MOBILE_VERIFY, state);
  }
  if (previousRoute.key === ROUTES.CAPTURE_FACE) {
    return getPreviousRoute(ROUTES.CAPTURE_FACE, state);
  }

  if (!previousRoute.isShown) {
    // this will allow finding the next route with `isShown` set to true
    return getNextRoute(previousRoute.key, state);
  }

  return previousRoute.key;
}

export const ProcessDataProvider: FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState);
  const { setCurrentRoute, currentRoute, handleRedirectToHome } = useRouter();

  React.useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        username: state.username,
        deviceId: state.deviceId,
        orgSlug: state.orgSlug,
        orgName: state.orgName,
        spaceId: state.spaceId,
        spaceName: state.spaceName,
      });
    });
  }, [
    state.username,
    state.deviceId,
    state.orgSlug,
    state.spaceId,
    state.spaceName,
    state.orgName,
  ]);

  const stateRef = useRefValue(state);
  const currentRouteRef = useRefValue(currentRoute);

  const actions = useRef<IProcessActions>({
    resetProcessData: () => dispatch({ type: 'ResetProcessData' }),

    updateProcessData: (payload) =>
      dispatch({ type: 'UpdateProcessData', payload }),

    goBack: () => {
      setCurrentRoute(
        getPreviousRoute(currentRouteRef.current, stateRef.current)
      );
    },

    handleUploadImage: async (imageData) => {
      const { visitRequestId } = stateRef.current;
      if (!visitRequestId) throw new Error('No visit request found!');

      const { fileUrl } = await Api.uploadImageInVisitorApp(
        visitRequestId,
        imageData
      );
      return fileUrl;
    },

    handleScanVmsQr: async ({
      type: portalType,
      spaceSlug,
      token,
      visitRequestId,
    }) => {
      let latitude;
      let longitude;

      try {
        const geoCoords = await getDeviceGeolocation();
        if (geoCoords) {
          latitude = geoCoords.latitude;
          longitude = geoCoords.longitude;
        }
      } catch (error) {
        console.error(error);
      }

      const deviceId = (await getDeviceFingerprint()) || '';
      const { accessToken, expiresIn } = await Api.getVisitorAppAccessToken({
        deviceId,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        deviceLocation: { latitude, longitude } as any,
        space: spaceSlug,
        deviceUaString: navigator.userAgent,
        verifyToken: token, // giving this for some flows like invitation-precheckin or remote-checkin, will verify access-token, so otp won't be required
      });
      const { organizationConfig, spaceConfig, organization, space } =
        await Api.getVisitorAppConfig();

      dispatch({
        type: 'UpdateProcessData',
        payload: {
          // spaceId: +spaceSlug,
          accessToken,
          ...organizationConfig,
          ...spaceConfig,
          portalType,
          orgName: organization.name,
          spaceName: space.name,
          spaceId: spaceSlug,
          orgSlug: organization.slug,
          deviceId,
          username: deviceId,
          hasMobileFeaturePlan:
            organization.featureFlags.organizationsFaceRecognition === false,
        },
      });

      if (portalType === 'invite-precheckin') {
        if (!visitRequestId) {
          throw new Error(
            'Visit request id not found during invitation precheckin!'
          );
        }
        const {
          id,
          people,
          visit,
          spaceConfig: visitRequestSpaceConfig,
          visitDetails,
          visitRequiredFields,
        } = await Api.getVisitorAppVisitRequest(visitRequestId);

        if (!visit.nextAllowedState) {
          throw new Error('This invitation is either invalid or has expired!');
        }

        if (visit.nextAllowedState !== 'pre-checkin' && visit.visitorBadgeUrl) {
          window.location.replace(visit.visitorBadgeUrl);
          return { expiresIn };
        }

        const isCheckinProcess = true;

        const payload = handleProcessVisitRequestInfo({
          isCheckinProcess,
          registerData: people,
          visitRequestInfo: {
            id,
            visitRequiredFields,
            spaceConfig: visitRequestSpaceConfig,
          },
          lastVisit: visitDetails?.lastVisit || null,
        });
        const { checkinFormSchema, checkoutFormSchema } =
          visitRequestSpaceConfig;
        const activeVisitValues = normalizeValueObject(
          visit && visit.checkinCustomData,
          checkinFormSchema?.fields
        );
        const prefillCheckinValues = normalizeValueObject(
          visit && visit.visitInvitation?.prefilledCheckinCustomData,
          checkinFormSchema?.fields
        );
        const prefillCheckoutValues = normalizeValueObject(
          visit && visit.visitInvitation?.prefilledCheckoutCustomData,
          checkoutFormSchema?.fields
        );

        dispatch({
          type: 'UpdateProcessData',
          payload: {
            personData: people,
            isCheckinProcess,
            prefillCheckinValues,
            prefillCheckoutValues,
            activeVisitValues,
            ...payload,
          },
        });
      }

      setCurrentRoute(
        getNextRoute(currentRouteRef.current, {
          ...stateRef.current,
          hasMobileFeaturePlan:
            organization.featureFlags.organizationsFaceRecognition === false,
          portalType,
        })
      );

      return { expiresIn };
    },

    handleCheckinByFaceSubmit: async (faceBlobData: IBlobWithDataURL) => {
      const { faceImageUrl } = await Api.registerFaceInVisitorApp(faceBlobData);

      dispatch({
        type: 'UpdateProcessData',
        payload: {
          faceData: { dataUrl: faceBlobData.dataURL, imageUrl: faceImageUrl },
        },
      });

      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleSendOtpToMobile: async (mobileNumber) => {
      const countriesData = await getCountriesData();
      if (
        !mobileNumber ||
        !validatePhoneWithPrefix(mobileNumber, countriesData)
      ) {
        throw new Error('Please enter a valid mobile number!');
      }

      const { countryCallingCode } = stateRef.current;

      await Api.sendVisitorAppOtp(mobileNumber, countryCallingCode);

      dispatch({ type: 'UpdateProcessData', payload: { mobileNumber } });

      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleResendOtpToMobile: async (mobile: string) => {
      await Api.resendVisitorAppOtp(mobile);
    },

    handleVerifyMobile: async (otp: string, mobile: string) => {
      const {
        otpLength,
        mobileNumber,
        checkinFormAutofillEnabled,
        checkoutFormAutofillEnabled,
      } = stateRef.current;

      if (!otp.trim() || otp.length !== otpLength) {
        throw new Error(`Please enter the OTP sent to ${mobileNumber}!`);
      }

      await Api.verifyVisitorAppOtp(otp, mobile);

      const { person, visitDetails } = await Api.getVisitorAppPersonDetails();
      const { activeVisit, lastVisit } = visitDetails;
      const isCheckinProcess = !activeVisit as boolean;

      if (!isCheckinProcess) {
        if (activeVisit?.visitorBadgeUrl) {
          window.location.replace(activeVisit.visitorBadgeUrl);
          return;
        }

        handleRedirectToHome();
        throw new Error(
          'You already checked in! Use QR received during pre-checkin to checkout!'
        );
      }
      // unreachable code - please remove while refactoring
      const activeVisitValues = normalizeValueObject(
        activeVisit && activeVisit?.checkinCustomData
      );

      let lastVisitValues = null;

      if (isCheckinProcess && checkinFormAutofillEnabled) {
        lastVisitValues = normalizeValueObject(lastVisit?.checkinCustomData);
      }

      if (!isCheckinProcess && checkoutFormAutofillEnabled) {
        lastVisitValues = normalizeValueObject(lastVisit?.checkoutCustomData);
      }

      dispatch({
        type: 'UpdateProcessData',
        payload: {
          personData: person,
          registerData: {
            firstName: '',
            lastName: '',
            ...person,
            mobileNumber: mobileNumber || person?.mobileNumber || '',
          },
          activeVisit,
          isCheckinProcess,
          activeVisitValues,
          lastVisitValues,
          lastVisit,
        },
      });

      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleSubmitRegisterForm: async (registerData) => {
      if (!registerData.firstName) {
        throw new Error('Please enter your first name!');
      }

      if (!registerData.lastName) {
        throw new Error('Please enter your last name!');
      }

      const countriesData = await getCountriesData();
      if (
        !registerData.mobileNumber ||
        !validatePhoneWithPrefix(registerData.mobileNumber, countriesData)
      ) {
        throw new Error('Please enter a valid mobile number!');
      }

      const { isCheckinProcess, activeVisit, lastVisit, personData, faceData } =
        stateRef.current;

      let visitRequestInfo: IVisitRequest;
      if (isCheckinProcess) {
        visitRequestInfo = await Api.createVisitorAppVisitRequest(
          personData
            ? {
                people: {
                  firstName: personData.firstName,
                  lastName: personData.lastName,
                  mobileNumber: personData.mobileNumber,
                },
              }
            : {
                people: {
                  firstName: registerData.firstName,
                  lastName: registerData.lastName,
                  mobileNumber: registerData.mobileNumber,
                },
                faceImageUrl: faceData?.imageUrl || undefined,
              }
        );
      } else {
        if (!activeVisit?.visitRequestId) {
          throw new Error('Visit request id not found during checkout!');
        }
        visitRequestInfo = await Api.getVisitorAppVisitRequest(
          activeVisit.visitRequestId
        );
      }

      const payload = handleProcessVisitRequestInfo({
        isCheckinProcess,
        registerData,
        visitRequestInfo,
        lastVisit,
      });
      dispatch({ type: 'UpdateProcessData', payload });

      // important to pass newly calculated values, as state might not have been updated yet by dispatch
      setCurrentRoute(
        getNextRoute(currentRouteRef.current, {
          ...stateRef.current,
          ...payload,
        })
      );
    },

    handleConfirmInvite: async () => {
      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleCaptureFace: async (faceBlobData: IBlobWithDataURL) => {
      const { visitRequestId } = stateRef.current;

      if (!visitRequestId) {
        throw new Error('No visit request found!');
      }

      const { imageUrl } = await Api.uploadFaceInVisitorApp(
        visitRequestId,
        faceBlobData
      );
      dispatch({
        type: 'UpdateProcessData',
        payload: { faceData: { dataUrl: faceBlobData.dataURL, imageUrl } },
      });

      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleSubmitScreeningForm: async (screeningFormData) => {
      const { screeningFormSchema } = stateRef.current;

      validateFields(screeningFormSchema?.fields || [], screeningFormData);
      dispatch({ type: 'UpdateProcessData', payload: { screeningFormData } });

      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleSubmitVaccinationForm: async (
      vaccinationCertificateData: VerifyVaccinationCertificate | null,
      skip = false
    ) => {
      if (!vaccinationCertificateData && !skip) {
        return;
      }
      dispatch({
        type: 'UpdateProcessData',
        payload: {
          vaccinationCertificateData: parseVaccinationData(
            vaccinationCertificateData
          ),
        },
      });
      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleSubmitCustomForm: async (customFormData) => {
      dispatch({ type: 'UpdateProcessData', payload: { customFormData } });

      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleSubmitTerms: async (signatureImageDataUrl, tncEmail) => {
      dispatch({
        type: 'UpdateProcessData',
        payload: { signatureImage: signatureImageDataUrl, tncEmail },
      });
      setCurrentRoute(getNextRoute(currentRouteRef.current, stateRef.current));
    },

    handleProcessConfirm: async () => {
      const {
        activeVisit,
        registerData,
        isCheckinProcess,
        checkinFormSchema,
        checkoutFormSchema,
        customFormData,
        visitRequestId,
        faceData,
        screeningFormSchema,
        screeningFormData,
        signatureImage,
        tncEmail,
        customFormSchemaRevisionId,
        vaccinationCertificateData,
        visitorBadgeAutoDownloadEnabled,
        isCustomFormRequired,
        isFaceRequired,
        isScreeningRequired,
      } = stateRef.current;

      if (!registerData) {
        throw new Error('Register details not found!');
      }

      if (!visitRequestId) {
        throw new Error('No visit request found!');
      }

      if (!customFormSchemaRevisionId) {
        throw new Error('Custom form revision id not found');
      }

      const processType = isCheckinProcess
        ? ProcessType.Checkin
        : ProcessType.Checkout;

      const fields = getFieldsDataFromConfig(
        isCheckinProcess,
        checkinFormSchema,
        checkoutFormSchema
      ).filter((field) => field.processType === processType);

      const activeVisitValues = normalizeValueObject(
        activeVisit && activeVisit.checkinCustomData,
        checkinFormSchema?.fields
      );
      const values = { ...activeVisitValues, ...customFormData };
      const fieldsWithIsVisibleProperty = getVisibleFields(
        fields,
        values,
        processType,
        true
      );

      const fieldsData: IFormFieldData[] = fieldsWithIsVisibleProperty.reduce(
        (result: IFormFieldData[], field) => {
          if (field.isVisible) {
            const { name } = field;
            const { [name]: fieldValue = null } = customFormData || {};

            // if field is not visible value should be empty
            result.push(getValueObject(field, fieldValue) as IFormFieldData);
          }
          return result;
        },
        []
      );

      let currentMetaData: IClientMetaData | null = {
        client: {
          deviceId: '',
          geoCoord: undefined,
        },
      };

      try {
        const deviceId = await getDeviceFingerprint();
        currentMetaData.client.deviceId = deviceId;
      } catch (e) {
        console.error('No device fingerprint found!');
      }
      try {
        const geoCoords = await getDeviceGeolocation();
        if (geoCoords) {
          currentMetaData.client.geoCoord = {
            long: geoCoords.longitude,
            lat: geoCoords.latitude,
            accuracyM: geoCoords.accuracy,
          };
        }
      } catch (error) {
        console.error(error);
      }

      currentMetaData =
        currentMetaData.client.deviceId || currentMetaData.client.geoCoord
          ? currentMetaData
          : null;

      if (isCheckinProcess) {
        const { visit } = await Api.preCheckinInVisitorApp(visitRequestId, {
          checkinPhotoUrl: isFaceRequired ? faceData?.imageUrl : undefined,
          checkinCustomData: isCustomFormRequired
            ? {
                data: fieldsData,
                formSchemaRevisionId: customFormSchemaRevisionId,
                identifier: checkinFormSchema.identifier,
              }
            : undefined,
          screeningFormData:
            isScreeningRequired || screeningFormData
              ? {
                  identifier: screeningFormSchema?.identifier,
                  data: screeningFormSchema?.fields
                    .filter(
                      (field) => field.name !== SCREENING_DECLARATION_FIELD_KEY
                    )
                    .map((field) => {
                      const { name } = field;
                      const { [name]: fieldValue = null } =
                        screeningFormData || {};

                      // if field is not visible value should be empty
                      return getValueObject(
                        field,
                        fieldValue
                      ) as IFormFieldData;
                    }),
                }
              : undefined,
          preCheckinMetadata: currentMetaData,
          signatureImageData: signatureImage || undefined,
          sendTncCopyTo: tncEmail || undefined,
          ...(vaccinationCertificateData || {}),
        });
        dispatch({
          type: 'UpdateProcessData',
          payload: {
            qrCodeBase64: visit.qrCodeBase64,
            visitorBadgeUrl: visit.visitorBadgeUrl,
          },
        });
      } else {
        if (!activeVisit) {
          throw new Error('Visit not found!');
        }

        const { visit } = await Api.preCheckoutInVisitorApp(visitRequestId, {
          checkoutPhotoUrl: isFaceRequired ? faceData?.imageUrl : undefined,
          preCheckoutMetadata: currentMetaData,
          // checkoutCustomData: isCustomFormRequired
          //   ? { data: fieldsData, formSchemaRevisionId: customFormSchemaRevisionId }
          //   : undefined,
        });
        dispatch({
          type: 'UpdateProcessData',
          payload: {
            qrCodeBase64: visit.qrCodeBase64,
            visitorBadgeUrl: visit.visitorBadgeUrl,
          },
        });
      }
    },
  });

  return (
    <ProcessDataContext.Provider value={{ ...state, ...actions.current }}>
      {children}
    </ProcessDataContext.Provider>
  );
};
