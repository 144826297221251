/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Spin, Result } from 'antd';
import {
  Button,
  Alert,
  toast,
  IErrorResponse,
} from '@datapeace/1up-frontend-web-ui';
import moment from 'moment';
import {
  LoadingOutlined,
  QrcodeOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import {
  useProcessDataContext,
  useRouter,
} from '@datapeace/vms-visitor-models';
import styles from './vms-qr-scanner-screen.module.scss';
import {
  getDeviceGeolocation,
  getImageFromVideo,
  getPermissionState,
  useIsWindowFocused,
  useQrReader,
} from '@datapeace/1up-frontend-web-utils';
import { ROUTES } from '@datapeace/vms-visitor-utils';
import {
  CameraPermissionError,
  Content,
  Header,
  Layout,
} from '@datapeace/vms-visitor-components';

const invitePrecheckinPath = '/invitation-precheckin/';
const precheckinVisitRequestIdParamKey = 'vrid';
const visitorBadge = '/visitor-badge/';
const VisitInvitationVisitorBadge = '/visitor-invitation-badge';

function getDataFromUrl(url: string) {
  try {
    const { pathname, searchParams } = new URL(url);

    // handle invite-precheckin path
    if (pathname.startsWith(invitePrecheckinPath)) {
      // '/invite/space/token' gives ['', 'invite', 'space', 'token']. so ignore first two items
      const [, , spaceSlug, token] = pathname.split('/');
      const visitRequestId = +(
        searchParams.get(precheckinVisitRequestIdParamKey) || ''
      );
      if (spaceSlug && token && visitRequestId) {
        return {
          type: 'invite-precheckin' as const,
          spaceSlug,
          token,
          visitRequestId,
        };
      }
    }

    if (pathname.startsWith(visitorBadge)) {
      return {
        type: 'visitor-badge' as const,
      };
    }
    if (pathname.startsWith(VisitInvitationVisitorBadge)) {
      return {
        type: 'visitor-invitation-badge' as const,
      };
    }

    // handle default (vms visitor)
    // '/space/' gives ['', 'space']. so ignore first item
    const [, spaceSlug] = pathname.split('/');
    if (!spaceSlug) return null;
    return {
      type: 'vms-visitor' as const,
      spaceSlug,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const VmsQrScanner = () => {
  const { handleScanVmsQr, resetProcessData } = useProcessDataContext();
  const { setCurrentRoute } = useRouter();
  const [isUrlCheckedForData, setIsUrlCheckedForData] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [videoElement, setVideoElement] = useState<Webcam | null>(null);
  const [qrReaderCanvasElement, setQrReaderCanvasElement] =
    useState<HTMLCanvasElement | null>(null);
  const [isRearCameraPosition, setIsRearCameraPosition] = useState(true);
  const [validationError, setValidationError] = useState<{
    status: number;
    message: string;
  } | null>(null);
  const [hasCameraPermission, setHasCameraPermission] = useState(true);

  const isWindowFocused = useIsWindowFocused(true);

  async function checkCameraPermission() {
    try {
      // check camera permission
      if ((await getPermissionState('camera' as any)) !== 'granted') {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });
        stream.getTracks().forEach((track) => track.stop());
      }
      setHasCameraPermission(true);
      return true;
    } catch (err) {
      setHasCameraPermission(false);
    }

    return false;
  }

  useEffect(() => {
    (async () => {
      const data = getDataFromUrl(window.location.toString());

      if (data && data.type === 'visitor-badge') {
        setCurrentRoute(ROUTES.VISITBADGE);
        return;
      }

      if (data && data.type === 'visitor-invitation-badge') {
        setCurrentRoute(ROUTES.VISIT_INVITATION_VISITOR_BADGE);
        return;
      }

      if (!(await checkCameraPermission())) {
        setIsUrlCheckedForData(true);
        return;
      }

      if (!data) {
        setIsUrlCheckedForData(true);
        return;
      }

      try {
        const { expiresIn } = await handleScanVmsQr(data);
        toast.info({
          message: `This link is valid for ${moment
            .duration(expiresIn, 'seconds')
            .humanize()}`,
        });
      } catch (err) {
        console.error(err);
        const errorStatus = (err as any)?.response?.status;
        const errorMessage =
          (errorStatus === 403 || errorStatus === 429) &&
          (err as any)?.response?.data?.error?.message === 'string'
            ? (err as any)?.response?.data?.error?.message
            : 'The link you followed is invalid or expired.';
        setValidationError({
          status: errorStatus,
          message: errorMessage,
        });
        setIsUrlCheckedForData(true);
      }
    })();
  }, [handleScanVmsQr, setCurrentRoute]);

  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        await getDeviceGeolocation();
      } catch (error) {
        console.error('Error fetching geolocation:', error);
        toast.error({
          message:
            'Unable to fetch location due to restricted permission. You may still continue.',
        });
      }
    };

    fetchGeolocation();
  }, []);

  useEffect(() => {
    resetProcessData();
  }, [resetProcessData]);

  const onQrFound = async (qrContent: string) => {
    try {
      if (videoElement?.video) {
        const faceData = await getImageFromVideo(videoElement.video);
        setPreviewImage(faceData?.dataURL || '');
      }
      const data = getDataFromUrl(qrContent);
      if (!data) throw new Error('Invalid QR!');
      if (data.type !== 'vms-visitor') throw new Error('Invalid Route');
      const { expiresIn } = await handleScanVmsQr(data);
      toast.info({
        message: `This link is valid for ${expiresIn / 60} minutes`,
      });
    } catch (err) {
      toast.error(err as IErrorResponse);
      setPreviewImage('');
    }
  };

  const handleCameraPosition = () => {
    setIsRearCameraPosition(!isRearCameraPosition);
  };

  useQrReader(
    videoElement?.video || null,
    qrReaderCanvasElement,
    true,
    onQrFound
  );

  if (validationError) {
    return (
      <Layout>
        <Header showHome={false} />
        <Content>
          <Result
            status="warning"
            title={validationError?.message}
            subTitle={
              validationError?.status === 429
                ? 'Please wait a few minutes before you try again. Thank you for your patience.'
                : 'You can close this tab now.'
            }
          />
        </Content>
      </Layout>
    );
  }

  if (!isUrlCheckedForData) {
    return (
      <Spin
        style={{ margin: 'auto' }}
        indicator={<LoadingOutlined />}
        size="large"
        tip="Processing... Please wait! "
      />
    );
  }

  if (!hasCameraPermission) return <CameraPermissionError />;

  return (
    <div className={styles.Layout}>
      <div className={styles.HintText}>
        <div>
          {previewImage ? (
            <Spin
              indicator={<LoadingOutlined />}
              size="large"
              tip="Processing... Please wait! "
            />
          ) : (
            <span>
              <QrcodeOutlined className={styles.HintTextIcon} />
              Scan VMS QR
            </span>
          )}
        </div>
      </div>

      <Button
        icon={<SwapOutlined />}
        size="large"
        className={styles.CameraPosition}
        onClick={handleCameraPosition}
        shape="circle"
      />

      <div className={styles.Content}>
        {previewImage && <img src={previewImage} alt="" />}

        {!previewImage &&
          (() => {
            return isWindowFocused ? (
              <>
                <Webcam
                  audio={false}
                  autoPlay
                  ref={setVideoElement}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: isRearCameraPosition ? 'environment' : 'user',
                  }}
                />
                <canvas
                  width={434}
                  height={411}
                  ref={setQrReaderCanvasElement}
                />
                <div className={styles.QrFrame} />
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Alert
                  message="Tap on screen to see camera view!"
                  description="This window is out of focus. Tap here to see the camera view."
                  type="warning"
                  showIcon
                />
              </div>
            );
          })()}
      </div>
    </div>
  );
};
