import { useState } from 'react';
import { Button, IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import { CameraOutlined } from '@ant-design/icons';
import ReactWebcam from 'react-webcam';
import styles from './web-cam.module.scss';
import {
  IBlobWithDataURL,
  getImageFromVideo,
} from '@datapeace/1up-frontend-web-utils';
import { Loader } from '../loader/loader';

export const defaultIdleTime = 15 * 60 * 1000; // 15 minutes

interface IProps {
  facingMode?: 'user' | 'environment';
  quality?: number;
  onCapture: (blob: IBlobWithDataURL | null) => void;
}

export const Webcam = ({ facingMode = 'user', quality, onCapture }: IProps) => {
  const [previewImage, setPreviewImage] = useState<string>('');
  const [videoElement, setVideoElement] = useState<ReactWebcam | null>(null);

  const handleCapture = async () => {
    try {
      if (!videoElement?.video) {
        throw new Error('Failed to capture (No video!)');
      }

      const blob = await getImageFromVideo(videoElement.video, quality);

      if (!blob || !blob.size) {
        throw new Error('Failed to capture! Please try again');
      }

      setPreviewImage(blob.dataURL);
      await onCapture(blob);
    } catch (e) {
      setPreviewImage('');
      toast.error(e as IErrorResponse);
      onCapture(null);
    }
  };

  return (
    <div className={styles.CameraWrapper}>
      <div className={styles.CardContent}>
        {previewImage && <img src={previewImage} alt="" />}

        {!previewImage && (
          <ReactWebcam
            audio={false}
            autoPlay
            ref={setVideoElement}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode }}
          />
        )}
      </div>
      <div className={styles.Capture}>
        {previewImage ? (
          <Loader inline text="" />
        ) : (
          <Button
            shape="circle"
            className={styles.CaptureButton}
            disabled={!videoElement}
            onClick={handleCapture}
          >
            <CameraOutlined />
          </Button>
        )}
      </div>
    </div>
  );
};
