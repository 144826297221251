/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useState } from 'react';
import { CloseOutlined, CameraOutlined } from '@ant-design/icons';
import { Button, Alert, Modal } from '@datapeace/1up-frontend-web-ui';
import { Webcam } from '@datapeace/vms-visitor-components';
import {
  IBlobWithDataURL,
  useIsWindowFocused,
} from '@datapeace/1up-frontend-web-utils';

export interface ICameraProps {
  name: string;
  onChange: (name: string, url: string) => void;
  value?: string;
  disabled?: boolean;
  onUploadFile?: (imageData: IBlobWithDataURL) => string;
}

export const Camera: FC<ICameraProps> = ({
  name,
  onChange,
  value,
  disabled,
  onUploadFile,
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const isWindowFocused = useIsWindowFocused(true);

  const handleCapture = async (imageData: IBlobWithDataURL | null) => {
    if (!imageData) {
      setPreviewImage('');
      onChange(name, '');
      return;
    }

    setIsCameraOpen(false);
    setPreviewImage(imageData.dataURL);
    if (!onUploadFile) {
      console.warn(
        'onUploadFile not found for camera field. base64 will be used.'
      );
      onChange(name, imageData.dataURL);
      return;
    }
    const imageUrl = await onUploadFile(imageData);
    onChange(name, imageUrl);
  };

  const handleClearImage = () => {
    setPreviewImage('');
    if (value) {
      onChange(name, '');
    }
  };

  const imageToShow = previewImage || value;

  return (
    <div>
      {/* @ts-ignore */}
      <Modal
        destroyOnClose
        bodyStyle={{ padding: 0 }}
        visible={isCameraOpen}
        onCancel={() => setIsCameraOpen(false)}
        footer={null}
      >
        {isWindowFocused ? (
          <Webcam onCapture={handleCapture} />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '1rem',
            }}
          >
            <Alert
              message="Tap on screen to see camera view!"
              description="This window is out of focus. Tap here to see the camera view."
              type="warning"
              showIcon
            />
          </div>
        )}
      </Modal>

      {imageToShow && (
        <img
          style={{
            width: '8em',
            height: '8em',
            background: '#eee',
            border: '1px solid #ddd',
            display: 'inline-block',
            verticalAlign: 'middle',
            objectFit: 'contain',
          }}
          src={imageToShow}
          alt=""
        />
      )}

      <Button
        style={{ margin: imageToShow && '0 1em' }}
        onClick={imageToShow ? handleClearImage : () => setIsCameraOpen(true)}
        size="large"
        icon={imageToShow ? <CloseOutlined /> : <CameraOutlined />}
        disabled={disabled}
      >
        {imageToShow ? 'Clear' : 'Take photo'}
      </Button>
    </div>
  );
};
