import {
  ChromeIcon,
  SafariIcon,
  FirefoxIcon,
} from '@datapeace/vms-visitor-icons';
import {
  Result,
  Row,
  Typography,
  Button as AntdButton,
  Space,
  Col,
} from 'antd';
import { Button, ReloadOutlined } from '@datapeace/1up-frontend-web-ui';
import { Content, Header, Layout } from '../layout';

export function CameraPermissionError() {
  return (
    <Layout>
      <Header showHome={false} />
      <Content>
        <Result
          title="Oops! Can&#39;t access camera"
          status="warning"
          subTitle={
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Typography.Text type="secondary">
                  This app needs camera access to continue. Please allow camera
                  access in browser settings and refresh the page.
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Space align="center">
                  <AntdButton
                    size="middle"
                    type="link"
                    href="https://support.google.com/chrome/answer/2693767"
                    icon={<ChromeIcon />}
                    target="__blank"
                    rel="noopener noreferrer"
                    title="Chrome"
                  />
                  <AntdButton
                    size="middle"
                    type="link"
                    href="https://support.mozilla.org/en-US/kb/camera-permissions"
                    icon={<FirefoxIcon />}
                    target="__blank"
                    rel="noopener noreferrer"
                    title="Firefox"
                  />
                  <AntdButton
                    size="middle"
                    type="link"
                    href="https://help.cer.bo/support/solutions/articles/8000084621-allow-access-to-camera"
                    icon={<SafariIcon />}
                    target="__blank"
                    rel="noopener noreferrer"
                    title="Safari"
                  />
                </Space>
              </Col>
            </Row>
          }
          extra={
            <Button
              onClick={() => window.location.reload()}
              icon={<ReloadOutlined />}
            >
              Refresh
            </Button>
          }
        />
      </Content>
    </Layout>
  );
}
