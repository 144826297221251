import React, { useState, useEffect, useRef, FC, useCallback } from 'react';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  TagOutlined,
  CommentOutlined,
  MobileOutlined,
  UserOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Button, Result, Tooltip, Typography } from 'antd';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { Helmet } from 'react-helmet';
import {
  downloadFileFromUrl,
  IVisitBadge,
} from '@datapeace/1up-frontend-shared-api';
import { ResultStatusType } from 'antd/lib/result';
import {
  defaultLogo,
  Api,
  imageWithTimestamp,
} from '@datapeace/vms-visitor-utils';
import {
  Layout,
  Header,
  Content,
  Loader,
} from '@datapeace/vms-visitor-components';
import styles from './visit-badge-screen.module.scss';

function getDataFromUrl(url: string) {
  try {
    const { pathname, searchParams } = new URL(url);

    const [, , visitId] = pathname.split('/');
    const token = searchParams.get('token');
    const isDownloadParam = searchParams.get('download') === 'true';
    if (visitId && token) {
      return {
        token,
        visitId: Number(visitId),
        isDownloadParam,
      };
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

function InfoListItem({
  icon: Icon,
  content = null,
  children = null,
  tooltip,
  html2canvasIgnore = false,
}: {
  icon: typeof CalendarOutlined;
  content?: React.ReactNode;
  children?: React.ReactNode;
  tooltip: string;
  html2canvasIgnore?: boolean;
}) {
  return (
    <Tooltip title={tooltip} placement="leftTop">
      <div
        className={styles.InfoListItem}
        id={html2canvasIgnore ? 'data-html2canvas-ignore' : 'InfoListItem'}
      >
        <Icon className={styles.InfoIcon} />
        <div className={styles.InfoContent}>{children || content}</div>
      </div>
    </Tooltip>
  );
}

export const VisitBadge: FC = () => {
  const [visitToken] = useState(() =>
    getDataFromUrl(window.location.toString())
  );

  const [badgeDetails, setBadgeDetails] = useState<null | IVisitBadge>(null);
  const [isComplete, setIsComplete] = useState(false);
  const [validationStatus, setValidationStatus] = useState<{
    status: ResultStatusType;
    message: string;
  }>({
    status: '500',
    message: '',
  });
  const refContainer = useRef<HTMLDivElement | null>(null);

  const startDate = moment(badgeDetails?.visitInvitation?.start);
  const endDate = moment(badgeDetails?.visitInvitation?.end);
  const checkinAt = moment(badgeDetails?.checkinAt);
  const preCheckinAt = moment(badgeDetails?.preCheckinAt);

  const isSameDate = startDate.isSame(endDate, 'day');
  const startDateString = startDate.format('Do MMM YYYY, h:mm A');
  const checkinAtString = checkinAt.format('Do MMM YYYY, h:mm A');
  const preCheckinAtString = preCheckinAt.format('Do MMM YYYY, h:mm A');
  const endDateString = isSameDate
    ? endDate.format('LT')
    : endDate.format('Do MMM YYYY, h:mm A');

  useEffect(() => {
    async function fetchVisitBadge() {
      if (!visitToken?.token && !visitToken?.visitId) {
        setValidationStatus({
          status: '404',
          message: `Oops! It seems you're missing vital info to access the badge data.`,
        });
        setIsComplete(true);
        return;
      }
      const { token, visitId } = visitToken as {
        token: string;
        visitId: number;
      };
      try {
        const { visit } = await Api.getVisitorBadgeInVisitorApp(visitId, token);
        setBadgeDetails(visit);
      } catch (err) {
        console.error(err);
        const responseStatus = (err as any)?.response?.status;
        if (responseStatus === 400) {
          setValidationStatus({
            status: '404',
            message: 'The link you followed is not valid or has expired.',
          });
        } else {
          setValidationStatus({
            status: '500',
            message: 'Oops, something went wrong. Please try again later.',
          });
        }
        setIsComplete(true);
      }
    }
    fetchVisitBadge();
  }, [visitToken]);

  const download = useCallback(() => {
    const container = refContainer.current;
    if (!container) {
      return;
    }
    html2canvas(container, {
      allowTaint: false,
      useCORS: true,
      ignoreElements(element) {
        if (element.id === 'data-html2canvas-ignore') {
          return true;
        }
        return false;
      },
      onclone: (document) => {
        const hiddenDiv = document.getElementById('hidden-div');
        if (hiddenDiv) {
          hiddenDiv.style.display = 'inline-flex';
        }
      },
    }).then((canvas) => {
      const url = canvas.toDataURL('image/png', 0.95);
      downloadFileFromUrl(url, `visit-badge-${badgeDetails?.id}.png`);
    });
  }, [badgeDetails]);

  useEffect(() => {
    if (badgeDetails) {
      if (visitToken?.isDownloadParam && badgeDetails?.qrCodeBase64) {
        download();
      }
    }
  }, [badgeDetails, visitToken, download]);

  if (isComplete) {
    return (
      <>
        <Helmet>
          <title>Visitor Badge</title>
        </Helmet>
        <Header imgSrc={badgeDetails?.orgLogoUrl || defaultLogo} />
        <Result
          status={validationStatus.status}
          title={validationStatus.message}
          subTitle="You can close this tab now."
        />
      </>
    );
  }

  if (!badgeDetails) {
    return (
      <>
        <Helmet>
          <title>Visitor Badge</title>
        </Helmet>
        <Loader text="Loading..." inline={false} />
      </>
    );
  }
  return (
    <Layout>
      <Helmet>
        <title>Visitor Badge</title>
      </Helmet>
      <Header imgSrc={badgeDetails?.orgLogoUrl || defaultLogo} />
      <Content>
        <div className={styles.Content}>
          <div ref={refContainer}>
            <div className={styles.Header} style={{ textAlign: 'center' }}>
              <img
                src={
                  imageWithTimestamp(badgeDetails?.orgLogoUrl as string) ||
                  imageWithTimestamp(defaultLogo)
                }
                alt="logo"
                className={styles.BrandImage}
                style={{ display: 'none' }}
                crossOrigin="anonymous"
                id="hidden-div"
              />
              <Typography.Title
                level={3}
                style={{
                  textAlign: 'center',
                }}
                type="secondary"
              >
                Visitor Badge
              </Typography.Title>
              <div className={styles.BadgeQrInfo}>
                This is your digital visitor badge, please show this QR code for
                seamless checkin/checkout.
              </div>
            </div>

            <div className={styles.InfoList}>
              {!!badgeDetails?.firstName && (
                <InfoListItem
                  icon={UserOutlined}
                  content={
                    <strong>{`${badgeDetails?.firstName} ${badgeDetails?.lastName}`}</strong>
                  }
                  tooltip="Visitor Name"
                />
              )}
              {!!badgeDetails?.mobileNumber && (
                <InfoListItem
                  icon={MobileOutlined}
                  content={badgeDetails?.mobileNumber}
                  tooltip="Visitor Mobile"
                />
              )}
              {!!badgeDetails?.visitInvitation?.name && (
                <InfoListItem
                  icon={TagOutlined}
                  content={badgeDetails?.visitInvitation?.name}
                  tooltip="Visit Invitation Name"
                />
              )}
              {!!badgeDetails?.visitInvitation?.start && (
                <InfoListItem
                  icon={ClockCircleOutlined}
                  tooltip="Visit Invitation Time"
                  content={
                    <>
                      {startDateString}
                      <span> - </span>
                      {!isSameDate && <br />}
                      {endDateString}
                    </>
                  }
                />
              )}
              {!badgeDetails?.visitInvitation?.start &&
                !!badgeDetails?.checkinAt && (
                  <InfoListItem
                    icon={ClockCircleOutlined}
                    tooltip="Visit Check-in Time"
                    content={checkinAtString}
                  />
                )}
              {!badgeDetails?.visitInvitation?.start &&
                !badgeDetails?.checkinAt &&
                !!badgeDetails?.preCheckinAt && (
                  <InfoListItem
                    icon={ClockCircleOutlined}
                    tooltip="Visit Pre - Check-in Time"
                    content={preCheckinAtString}
                  />
                )}
              {!!badgeDetails?.visitInvitation?.locationText && (
                <InfoListItem
                  icon={EnvironmentOutlined}
                  content={badgeDetails?.visitInvitation?.locationText}
                  tooltip="Visit Invitation Location"
                />
              )}
              {!!badgeDetails?.calendarUrls && (
                <InfoListItem
                  icon={CalendarOutlined}
                  tooltip="Add to calendar"
                  html2canvasIgnore
                  content={
                    <>
                      <strong>Add to Calendar</strong>
                      <div className={styles.CalendarLinks}>
                        <a
                          href={badgeDetails?.calendarUrls.google}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Google
                        </a>
                        &#8226;
                        <a
                          href={badgeDetails?.calendarUrls.outlook}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          Outlook
                        </a>
                        &#8226;
                        <a
                          href={badgeDetails?.calendarUrls.apple}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          Apple
                        </a>
                      </div>
                    </>
                  }
                />
              )}
              {!!badgeDetails?.visitInvitation?.visitorComment && (
                <InfoListItem
                  icon={CommentOutlined}
                  content={
                    badgeDetails?.visitInvitation?.visitorComment || '---'
                  }
                  tooltip="Visitor Comment"
                />
              )}
              {!!badgeDetails?.qrCodeBase64 && (
                <div className={styles.QrContainer}>
                  <img
                    alt="qr_code"
                    src={badgeDetails?.qrCodeBase64}
                    crossOrigin="anonymous"
                  />
                </div>
              )}
            </div>
            <div className={styles.Footer}>
              Powered by&nbsp;
              <a
                rel="noopener noreferrer"
                href={import.meta.env.VITE_APP_BRAND_DOMAIN}
                target="_blank"
              >
                {import.meta.env.VITE_APP_BRAND_NAME?.toUpperCase()}
              </a>
            </div>
          </div>

          <div className={styles.DownloadBtn}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              size="large"
              onClick={() => download()}
            >
              Download
            </Button>
          </div>
        </div>
      </Content>
    </Layout>
  );
};
