import { useState } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import {
  Layout,
  Header,
  Content,
  NavButtons,
  DynamicForm,
  Form,
} from '@datapeace/vms-visitor-components';
import {
  useProcessDataContext,
  IDynamicForm,
} from '@datapeace/vms-visitor-models';
import DefaultFormStyles from '../../components/form/form.module.scss';
import * as styles from '../custom-form-screen/custom-form-screen.module.scss';

export const ScreeningForm = () => {
  const {
    screeningFormSchema,
    handleSubmitScreeningForm,
    screeningFormData,
    goBack,
  } = useProcessDataContext();
  const [formData, setFormData] = useState<IDynamicForm>(
    screeningFormData || {}
  );

  const handleSubmit = async () => {
    try {
      await handleSubmitScreeningForm(formData);
    } catch (err) {
      toast.error(err as IErrorResponse);
    }
  };

  return (
    <Layout>
      <Header />

      <Content>
        <div
          className={clsx(DefaultFormStyles.Form, styles.Form, styles.Large)}
        >
          {!!screeningFormSchema.title && <h2>{screeningFormSchema.title}</h2>}
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DynamicForm
              values={formData}
              items={screeningFormSchema.fields}
              onChange={setFormData}
            />
            <NavButtons goBack={goBack} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
