import React, { FC, useRef } from 'react';
import { Select } from 'antd';
import { Avatar } from '@datapeace/1up-frontend-web-ui';
import { SelectProps } from 'antd/lib/select';
import clsx from 'clsx';
import styles from './index-dynamic-form-fields.module.scss';
import {
  getInitialsFromName,
  preventInputFocus,
} from '@datapeace/1up-frontend-shared-api';

export type DropdownValue =
  | string
  | number
  | { key: string; label: string; value: string };

export interface IDropdownProps
  extends Omit<SelectProps<DropdownValue>, 'onChange' | 'value' | 'options'> {
  name: string;
  onChange: (name: string, value: IDropdownProps['value']) => void;
  value?: DropdownValue;
  placeholder?: string;
  options: {
    key: string | number;
    label: string;
    iconSrc?: string;
    content?: JSX.Element | null;
  }[];
  disabled?: boolean;
}

function getValidDropdownValue(value?: DropdownValue, labelInValue?: boolean) {
  if (!value) {
    return value;
  }

  if (typeof value !== 'string' && !labelInValue) {
    return undefined;
  }

  return value;
}

export const Dropdown: FC<IDropdownProps> = ({
  onChange,
  name,
  value,
  placeholder,
  options,
  disabled,
  className,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleValueChange = (selectedValue: DropdownValue) => {
    const change =
      selectedValue && typeof selectedValue === 'object' && !selectedValue.key
        ? ''
        : selectedValue;
    onChange(name, change);
  };

  /**
   * used to prevent initial focus of input in dropdown
   */
  const handleOpenChange = (isOpen: boolean) => {
    preventInputFocus(isOpen, containerRef, '.ant-select-search__field');
  };

  const validatedValue = getValidDropdownValue(value, props.labelInValue);

  return (
    <div className={styles.DropdownWrapper} ref={containerRef}>
      <Select<DropdownValue>
        getPopupContainer={() => containerRef.current || document.body}
        onDropdownVisibleChange={handleOpenChange}
        className={clsx(styles.Dropdown, className)}
        showSearch={options.length > 5}
        allowClear
        placeholder={placeholder || 'Select an option'}
        value={validatedValue === '' ? undefined : validatedValue} // placeholder shown only if value is undefined
        onChange={handleValueChange}
        size="large"
        optionFilterProp="children"
        dropdownMatchSelectWidth={false}
        notFoundContent="Not found!"
        disabled={disabled}
        optionLabelProp="title"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        defaultValue={undefined}
      >
        {options.map(({ key, label, iconSrc }) => (
          <Select.Option key={key} value={key} title={label}>
            <Avatar
              src={iconSrc}
              size={50}
              className={styles.OptionIcon}
              alt={label}
            >
              {getInitialsFromName(label)}
            </Avatar>
            {label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
