import {
  VerifyVaccinationCertificate,
  verifyVaccinationCertificate,
} from '@datapeace/1up-frontend-shared-api';
import { checkVaccinationProcess } from '@datapeace/1up-frontend-web-utils';
import { Alert, Upload, message } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { UploadFile } from 'antd/lib/upload/interface';
import { AxiosInstance } from 'axios';
import { useState } from 'react';
import { IErrorResponse } from '../toast';
import { Button } from '../button';
import { UploadOutlined } from '../icons';
import styles from './vaccination-verification.module.scss';

export interface UploadVaccinationCertificateProps {
  onSubmit: (args: VerifyVaccinationCertificate | null) => void;
  isPartiallyVaccinatedAllowed: boolean;
  axiosInstance: AxiosInstance;
  btnSize: SizeType | 'xLarge';
}

export function UploadVaccinationCertificate({
  onSubmit,
  isPartiallyVaccinatedAllowed,
  axiosInstance,
  btnSize = 'middle',
}: UploadVaccinationCertificateProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function handleUploadVaccinationCertificate(
    vaccinationCertificate: File
  ) {
    setIsVerifying(true);
    try {
      const vaccinationCertificateData = await verifyVaccinationCertificate(
        vaccinationCertificate,
        axiosInstance
      );
      checkVaccinationProcess(
        vaccinationCertificateData,
        isPartiallyVaccinatedAllowed
      );
      message.success('Vaccination Certificate Verified!');
      setFileList([
        {
          name: vaccinationCertificate.name,
          size: vaccinationCertificate.size,
          type: vaccinationCertificate.type,
          uid: vaccinationCertificate.name,
          status: 'success',
        },
      ]);
      onSubmit(vaccinationCertificateData);
    } catch (e) {
      message.error((e as IErrorResponse).message);
      setFileList([
        {
          name: vaccinationCertificate.name,
          size: vaccinationCertificate.size,
          type: vaccinationCertificate.type,
          uid: vaccinationCertificate.name,
          status: 'error',
          response: (e as IErrorResponse).message,
        },
      ]);
      setErrorMessage((e as IErrorResponse).message);
      onSubmit(null);
    } finally {
      setIsVerifying(false);
    }
  }

  return (
    <>
      {errorMessage && (
        <Alert
          message={`Error: ${errorMessage}`}
          type="error"
          showIcon
          className={styles.UploadErrorMessage}
        />
      )}
      <Upload
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        fileList={fileList}
        multiple={false}
        accept={['.png', '.jpg', '.jpeg', '.pdf'].join(',')}
        customRequest={(uploadedFileArgs) => {
          handleUploadVaccinationCertificate(uploadedFileArgs.file as File);
        }}
        onChange={({ file }) => {
          setErrorMessage('');
          if (file.status === 'removed') {
            setFileList([]);
            onSubmit(null);
            return;
          }
          setFileList([file]);
        }}
      >
        <Button
          size={btnSize}
          icon={<UploadOutlined />}
          loading={isVerifying}
          style={{ marginBottom: '1rem' }}
        >
          Upload Vaccination Certificate
        </Button>
      </Upload>
    </>
  );
}
