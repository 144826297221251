/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useRef } from 'react';
import Moment from 'moment';
import { DatePicker, TimePicker } from 'antd';
import { TimePickerProps } from 'antd/lib/time-picker';
import styles from './index-dynamic-form-fields.module.scss';
import { preventInputFocus } from '@datapeace/1up-frontend-shared-api';

const displayFormats = {
  date: 'DD-MM-YYYY',
  time: 'hh:mm A',
  datetime: `DD-MM-YYYY hh:mm A`,
};

const valueFormats = {
  date: Moment.HTML5_FMT.DATE,
  time: Moment.HTML5_FMT.TIME_SECONDS,
  datetime: Moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
};

export interface IDateTimeProps {
  name: string;
  onChange: (name: string, val: any) => void;
  type: 'date' | 'time' | 'datetime';
  placeholder?: string;
  value?: string;
  disabled?: boolean;
}

/* eslint-disable react/jsx-props-no-spreading */

export const DateTime: FC<IDateTimeProps> = ({
  name,
  onChange,
  value,
  type,
  placeholder,
  disabled,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleValueChange = (t: moment.Moment | null) => {
    if (!t) {
      onChange(name, '');
      return;
    }

    let val = Moment(t as Moment.Moment)
      .utc()
      .format(valueFormats[type]);

    if (type === 'datetime') {
      val = `${val}Z`;
    }

    onChange(name, val);
  };

  /**
   * used to prevent initial focus of input in calendar
   */
  async function handleOpenChange(isOpen: boolean) {
    preventInputFocus(
      isOpen,
      containerRef,
      type === 'time' ? '.ant-time-picker-panel-input' : '.ant-calendar-input'
    );
  }

  const commonProps: TimePickerProps = {
    placeholder,
    onChange: handleValueChange,
    value: (value
      ? Moment.utc(value, valueFormats[type])?.local()
      : undefined) as any,
    allowClear: true,
    style: { display: 'block', width: '100%' },
    use12Hours: true,
    format: displayFormats[type],
    disabled,
    className: styles.Date,
    onOpenChange: handleOpenChange,
  };

  let picker: JSX.Element;

  if (type === 'time') {
    picker = (
      <TimePicker
        getPopupContainer={() => containerRef.current || document.body}
        size="large"
        {...commonProps}
        use12Hours
      />
    );
  } else {
    picker = (
      <DatePicker
        getPopupContainer={() => containerRef.current || document.body}
        size="large"
        {...commonProps}
        showTime={
          type === 'datetime' && {
            format: displayFormats.time,
            use12Hours: true,
          }
        }
      />
    );
  }

  return (
    <div className={styles.DateTimeWrapper} ref={containerRef}>
      {picker}
    </div>
  );
};
