import { FC } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { Button } from '@datapeace/1up-frontend-web-ui';
import { useRouter, RoutesValues } from '@datapeace/vms-visitor-models';
import styles from './nav-buttons.module.scss';

interface Props {
  backLink?: RoutesValues;
  backLinkText?: string;
  onNext?: () => void;
  goBack?: () => void;
  loading?: boolean;
  nextLinkText?: string;
  showNext?: boolean;
  showBack?: boolean;
}

export const NavButtons: FC<Props> = ({
  backLink,
  backLinkText = 'Back',
  onNext,
  goBack,
  nextLinkText = 'Continue',
  loading,
  showNext = true,
  showBack = true,
}) => {
  const { setCurrentRoute, handleRedirectToHome } = useRouter();

  function handleGoBack() {
    if (backLink) {
      setCurrentRoute(backLink);
    } else if (goBack) {
      goBack();
    } else {
      handleRedirectToHome();
    }
  }

  return (
    <Row justify="space-between" className={styles.Root}>
      {showBack && (
        <Button
          disabled={loading}
          onClick={() => handleGoBack()}
          className={styles.Button}
          size="large"
          icon={<ArrowLeftOutlined />}
        >
          {backLinkText}
        </Button>
      )}

      {showNext && (
        <Button
          loading={loading}
          onClick={onNext}
          className={styles.Button}
          size="large"
          htmlType="submit"
          type="primary"
        >
          {nextLinkText}
          <ArrowRightOutlined />
        </Button>
      )}
    </Row>
  );
};
