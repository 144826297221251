import { useCountdown } from '@datapeace/1up-frontend-web-utils';
import { useEffect } from 'react';

export function useRedirectOnDone(
  status: 'success' | 'error' | 'idle' | 'loading',
  route: string
) {
  // redirect on success
  const shouldRedirect = status === 'success';

  // after 10 seconds of successful redirect
  const [resendDelay] = useCountdown(10, shouldRedirect ? 1000 : null);
  useEffect(() => {
    if (shouldRedirect && resendDelay === 0 && route) {
      window.location.replace(route);
    }
  }, [shouldRedirect, resendDelay, route]);

  return [resendDelay, shouldRedirect];
}
