import React, { FC } from 'react';
import { Input } from 'antd';
import styles from './index-dynamic-form-fields.module.scss';
import { MultiLineText } from './multi-line-text-dynamic-field';

export interface ITextProps {
  name: string;
  onChange: (name: string, value: string) => void;
  type?: string;
  placeholder?: string;
  value?: string;
  multiline?: boolean;
  disabled?: boolean;
}

export const Text: FC<ITextProps> = ({
  name,
  placeholder,
  onChange,
  value,
  type,
  multiline,
  disabled,
}) => {
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, e.target.value);
  };

  if (multiline && type === 'text') {
    return (
      <MultiLineText
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    );
  }

  return (
    <Input
      className={styles.Text}
      disabled={disabled}
      size="large"
      type={type}
      name={name}
      value={value}
      onChange={handleValueChange}
      placeholder={placeholder}
    />
  );
};

Text.defaultProps = {
  multiline: false,
  type: 'text',
};
