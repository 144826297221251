import { FC } from 'react';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import { Button } from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import { useRouter, RoutesValues } from '@datapeace/vms-visitor-models';
import { defaultLogo } from '@datapeace/vms-visitor-utils';
import styles from './layout.module.scss';

interface Props {
  className?: string;
  backLink?: RoutesValues;
  showHome?: boolean;
  showHeaderLogo?: boolean;
  imgSrc?: string;
}

export const Header: FC<Props> = ({
  className,
  backLink,
  showHome = true,
  showHeaderLogo = true,
  imgSrc = undefined,
}) => {
  const { setCurrentRoute, handleRedirectToHome } = useRouter();

  return (
    <div className={clsx(styles.Header, className)}>
      {backLink && (
        <Button
          title="Go back"
          onClick={() => setCurrentRoute(backLink)}
          size="large"
          shape="circle"
          className={styles.BackButton}
          icon={<ArrowLeftOutlined />}
        />
      )}

      {showHeaderLogo && (
        <img
          className={styles.BrandImage}
          src={imgSrc || defaultLogo}
          alt={import.meta.env.VITE_APP_BRAND_NAME}
        />
      )}

      {showHome && (
        <Button
          title="Go to welcome page"
          onClick={handleRedirectToHome}
          size="large"
          shape="circle"
          className={styles.HomeButton}
          icon={<HomeOutlined />}
        />
      )}
    </div>
  );
};
