import React, { useEffect } from 'react';
import {
  configureVisitorAppClient,
  getCountriesData,
} from '@datapeace/1up-frontend-shared-api';
import {
  getDeviceFingerprint,
  removeDeprecatedDeviceFingerprintId,
} from '@datapeace/1up-frontend-shared-api';

configureVisitorAppClient({
  siteId: 'web-visitor',
  siteVersion: __VERSION__,
  deviceIdGenerator: getDeviceFingerprint,
});

export function AppInitializerContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    // remove the deprecated device fingerprint key
    removeDeprecatedDeviceFingerprintId();
    // fetch country codes data for caching
    getCountriesData().catch(() => null);
  }, []);
  return <>{children}</>;
}
