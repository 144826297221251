import { FC } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import styles from './index-dynamic-form-fields.module.scss';

export interface ICheckboxProps {
  name: string;
  onChange: (name: string, checkedItems: CheckboxValueType[]) => void;
  value?: [];
  options: [
    {
      key: string;
      label: string;
    }
  ];
  disabled?: boolean;
}

export const Checkbox: FC<ICheckboxProps> = ({
  name,
  onChange,
  value,
  options,
  disabled,
}) => {
  const handleValueChange = (checkedItems: CheckboxValueType[]) => {
    onChange(name, checkedItems);
  };

  return (
    <AntdCheckbox.Group
      disabled={disabled}
      value={value}
      onChange={handleValueChange}
    >
      {options.map(({ key, label }) => (
        <AntdCheckbox className={styles.Checkbox} key={key} value={key}>
          {label}
        </AntdCheckbox>
      ))}
    </AntdCheckbox.Group>
  );
};
