import { FC, useState } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import { IForm } from '@datapeace/1up-frontend-shared-api';
import {
  Layout,
  Header,
  Content,
  NavButtons,
  Form,
  DynamicForm,
} from '@datapeace/vms-visitor-components';
import {
  useProcessDataContext,
  IDynamicForm,
  ProcessType,
} from '@datapeace/vms-visitor-models';
import DefaultFormStyles from '../../components/form/form.module.scss';
import * as styles from './custom-form-screen.module.scss';
import { getVisibleFields, validateFields } from '../../utils/form';

export function getFieldsDataFromConfig(
  isCheckinProcess: boolean,
  checkInForm: IForm,
  checkOutForm: IForm
) {
  const checkinFields = ((checkInForm || {}).fields || []).map((field) => ({
    ...field,
    processType: ProcessType.Checkin,
  }));
  const checkoutFields = ((checkOutForm || {}).fields || []).map((field) => ({
    ...field,
    processType: ProcessType.Checkout,
  }));
  return (
    (isCheckinProcess
      ? checkinFields
      : [...checkinFields, ...checkoutFields]) || []
  );
}

export const CustomForm: FC = () => {
  // const { currentOrganization } = useConfig()

  const {
    activeVisitValues,
    prefillCheckinValues,
    prefillCheckoutValues,
    checkinFormSchema,
    checkoutFormSchema,
    isCheckinProcess,
    customFormData,
    lastVisitValues,
    handleUploadImage,
    handleSubmitCustomForm,
    goBack,
  } = useProcessDataContext();
  const [formData, setFormData] = useState<IDynamicForm>(customFormData || {});

  const fields = getFieldsDataFromConfig(
    isCheckinProcess,
    checkinFormSchema,
    checkoutFormSchema
  );
  // formData represents the current data being edited, must be in the top priority of values to enable dynamic-form two-way binding
  const values = {
    ...lastVisitValues,
    ...activeVisitValues,
    ...prefillCheckinValues,
    ...prefillCheckoutValues,
    ...formData,
  };
  const visibleFields = getVisibleFields(
    fields,
    values,
    isCheckinProcess ? ProcessType.Checkin : ProcessType.Checkout
  );

  const handleSubmit = () => {
    try {
      validateFields(visibleFields, values);
      handleSubmitCustomForm(formData);
    } catch (err) {
      toast.error(err as IErrorResponse);
    }
  };
  return (
    <Layout>
      <Header />

      <Content>
        <div className={styles.Form}>
          <Form
            className={DefaultFormStyles.Form}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <DynamicForm
              values={values}
              items={visibleFields}
              onUploadFile={handleUploadImage}
              onChange={setFormData}
            />
            <NavButtons goBack={goBack} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
