import {
  getVisitorAppAccessToken,
  getVisitorAppConfig,
  sendVisitorAppOtp,
  resendVisitorAppOtp,
  verifyVisitorAppOtp,
  getVisitorAppPersonDetails,
  getCountriesData,
  separateCallingCodeAndPhone,
  preCheckinInVisitorApp,
  preCheckoutInVisitorApp,
  uploadFaceInVisitorApp,
  uploadImageInVisitorApp,
  searchPersonInVisitorApp,
  getVisitorAppVisitRequest,
  createVisitorAppVisitRequest,
  checkIfPersonExistsInVisitorApp,
  registerFaceInVisitorApp,
  getVisitorBadgeInVisitorApp,
  getVisitInvitationBadge,
} from '@datapeace/1up-frontend-shared-api';
import { UnsupportedCallingCodeError } from './errors';

const validateCallingCodeAvailability = async (
  mobile: string,
  orgCallingCode: string
) => {
  const [prefix] = separateCallingCodeAndPhone(mobile);

  // throw error if country code is other than orgCallingCode (not set as calling code in organization settings)
  if (orgCallingCode && prefix !== orgCallingCode) {
    throw new UnsupportedCallingCodeError(
      `Country code (${prefix}) is not supported!`
    );
  }

  // throw error if country code is not in allowed list
  const { allowedCallingCodes } = await getCountriesData();
  if (!allowedCallingCodes.includes(prefix)) {
    throw new UnsupportedCallingCodeError(
      `Country code (${prefix}) is not supported!`
    );
  }
};

const Api = {
  getVisitorAppAccessToken,
  getVisitorAppConfig,
  sendVisitorAppOtp: async (mobileNumber: string, orgCallingCode = '') => {
    await validateCallingCodeAvailability(mobileNumber, orgCallingCode);
    return sendVisitorAppOtp(mobileNumber);
  },
  resendVisitorAppOtp,
  verifyVisitorAppOtp,
  getVisitorAppPersonDetails,
  preCheckinInVisitorApp,
  preCheckoutInVisitorApp,
  uploadFaceInVisitorApp,
  uploadImageInVisitorApp,
  searchPersonInVisitorApp,
  getVisitorAppVisitRequest,
  createVisitorAppVisitRequest,
  checkIfPersonExistsInVisitorApp,
  registerFaceInVisitorApp,
  getVisitorBadgeInVisitorApp,
  getVisitInvitationBadge,
};

export { Api };
