import { FC, FormEvent } from 'react';

export const Form: FC<JSX.IntrinsicElements['form']> = ({
  children,
  className,
  onSubmit,
  ...props
}) => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (!onSubmit) return;

    e.preventDefault();
    onSubmit(e);
  };

  return (
    <form className={className} onSubmit={handleSubmit} {...props}>
      {children}
    </form>
  );
};
